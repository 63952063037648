export class DeliveryMethod {
  public static DELIVERY = new DeliveryMethod("delivery", "deliveryMethodDiscount", "ORDER_TYPE.DELIVERY", 'qpass-so-delivery"', "delivery")
  public static DRIVE_THRU = new DeliveryMethod("drivethru", "driveThroughDiscount", "ORDER_TYPE.DRIVE_THRU", "qpass-so-car", "driveThrough")
  public static TAKE_AWAY = new DeliveryMethod("takeaway", "takeAwayDiscount", "ORDER_TYPE.TAKE_AWAY", "qpass-so-takeaway", "takeAway")
  public static Q_TABLE = new DeliveryMethod("qtable", "qTableDiscount", "ORDER_TYPE.QTABLE", "qpass-so-dinein", "qtableService");

  public static allValues = [DeliveryMethod.DELIVERY, DeliveryMethod.DRIVE_THRU, DeliveryMethod.TAKE_AWAY, DeliveryMethod.Q_TABLE];

  public type: string;
  public discountField: string;
  public message: string;
  public icon: string;
  public shopField: string;

  private constructor(type: string, discountField: string, message: string, icon: string, shopField: string) {
    this.type = type;
    this.discountField = discountField;
    this.message = message;
    this.icon = icon;
    this.shopField = shopField;
  }

  public static getDeliveryMethodByValue(type: string) {
    return this.allValues.find(item => item.type === type);
  }
}
