import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UserDataService } from "../providers/user-data/user-data.service";
import { filter, mergeMap, Observable, take, tap } from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private userDataService: UserDataService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ((req.url.indexOf(environment.QpassConfiguration.apiUrl) >= 0) &&
        !(req.url.endsWith("user/anonymous") || req.url.endsWith("user/login"))) {
      
      return this.userDataService.userToken$.pipe(
        filter((token) => !!token),
        take(1),
        mergeMap((userToken) => {
          req = req.clone({
            setHeaders: {
              'Authorization': `Bearer ${userToken}`,
            },
          });
          return next.handle(req);
        })
      )
    }
    else {
      return next.handle(req);
    }
  }
}