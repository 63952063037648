import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { AppLauncher } from '@capacitor/app-launcher';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-version-modal',
  templateUrl: './update-version-modal.component.html',
  styleUrls: ['./update-version-modal.component.scss']
})
export class UpdateVersionModalComponent implements OnInit {

  @Input() requireUpdate: boolean;
  @Input() allowUsage: boolean;

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit(): void {
  }

  onUpdate() {

    if (Capacitor.getPlatform() === 'ios') {
      AppLauncher.openUrl({url: environment.QpassConfiguration.iosUrl});
    }
    else if (Capacitor.getPlatform() === 'android') {
      Browser.open({url: environment.QpassConfiguration.androidUrl});
    }
  }

  onClose() {
    this.modalCtrl.dismiss();
  }

}
