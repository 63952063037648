import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { AppUpdate } from 'src/app/models/app-update.interface';
import { LinkRedirect } from '../../models/link-redirect.interface';

@Injectable({
  providedIn: 'root'
})
export class UpdateVersionService {

  constructor(
    private http: HttpClient,
    private logger: NGXLogger
  ) { }

  processLink(qrLink: string): Observable<LinkRedirect> {

    return this.http.get<LinkRedirect>(environment.QpassConfiguration.apiUrl + '/qr-link/' + qrLink, {}).pipe(
      catchError(this.handleError('providers.qr-redirect.processLink'))
    );

  }

  checkForUpdate(): Observable<AppUpdate> {

    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('app', 'user');

    return this.http.get<AppUpdate>(environment.QpassConfiguration.apiUrl + '/version/get', {params: url_params}).pipe(
      catchError(this.handleError('providers.update-version.checkForUpdate'))
    );

  }

  private handleError (operation = 'operation') {
    return (err: HttpErrorResponse) => {
      let errMsg: string = "USER_SRV.ERR";
      
      if (err.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        this.logger.log(`${operation} failed: ${err.error.message}`);
        errMsg = "USER_SRV.ERR_NO_WEB_ACCESS";
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        this.logger.log(`${operation} failed: Backend returned code ${err.status}, body was: ${err.error}`);
        errMsg = "USER_SRV.ERR_SERVER_COMM";
      }
      return throwError(errMsg);
    }
  }

}
