import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UserDataService } from "../providers/user-data/user-data.service";
import { filter, mergeMap, Observable, take } from "rxjs";
import { environment } from '../../environments/environment';

@Injectable()
export class AnonymousUserInterceptor implements HttpInterceptor {

  constructor(private userDataService: UserDataService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf(environment.QpassConfiguration.apiUrl) >= 0) {
      
      return this.userDataService.anonymousUserId$.pipe(
        filter((anonymousId) => !!anonymousId),
        take(1),
        mergeMap((anonymousId) => {
          req = req.clone({
            setHeaders: {
              'X-Anonymous-User-Token': anonymousId
            },
          });
          return next.handle(req);
        })
      )
    }
    return next.handle(req);
  }
}