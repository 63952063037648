<ion-content fullscreen="true">
  
  <ion-grid style="width: 100%; height: 100%;">
    <ion-row class="ion-align-items-center" style="height: 100%">
      <ion-col class="ion-justify-content-center ion-text-center" style="width: 100%">
        <div style="position: relative; height: 222px;">
          <div style="width: 222px; height: 222px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
            <img id="bgRect" src="assets/images/animOuterRect.svg"/>
          </div>
          <div style="width: 222px; height: 222px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
            <img id="logoOutline" src="assets/images/animOutlineLogo.svg"/>
          </div>
          <div style="width: 222px; height: 222px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
            <img id="innerCircle" src="assets/images/animInnerCircle.svg"/>
          </div>
        </div>
        <div>
          <h1 id="brandText" class="qpass-color-text-alt" style="margin-top: 0;">Q-pass</h1>
          <p id="brandSlogan" class="qpass-color-text-main" style="margin-top: 0;">Skip queue whenever possible!</p>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-grid>
      <ion-row style="height: 64px">
        <ion-col class="ion-text-center">
          <div *ngIf="!(checksResolved$ | async)">
            <div *ngIf="!(updateCheckError$ | async)">
              <ion-spinner *ngIf="splashTimedOut$ | async"></ion-spinner>
            </div>
            <ion-button *ngIf="updateCheckError$ | async" color="primary" expand="block" fill="solid" (click)="onRetryChecks()">{{ 'SPLASH.CONN_ERROR' | translate }}</ion-button>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>