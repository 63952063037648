
<ion-content scrollY="false">
    <ion-grid class="ion-margin-vertical ion-no-padding dialog-content">
      <ion-row style="margin: 24px 24px 16px 24px">
        <ion-col>
          <div class="qpass-text-body-alt-slim">{{'CAFE_DETAILS.HIDDEN_MSG' | translate}}</div>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-footer style="position: absolute;bottom: 0;">
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col>
            <ion-button color="primary" class="ion-no-margin" expand="full" fill="solid" (click)="dismiss()">
              <div>{{'APP.GOT_IT' | translate}}!</div>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
</ion-content>
  