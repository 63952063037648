import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-hidden-shop-modal',
  templateUrl: './hidden-shop-modal.component.html',
  styleUrls: ['./hidden-shop-modal.component.scss'],
})
export class HiddenShopModalComponent implements OnInit {

    constructor(
        private modalCtrl: ModalController,
        public navParams: NavParams,
    ) { }

    ngOnInit() {

    }

    dismiss() {
        this.modalCtrl.dismiss({
        });
    }
}


