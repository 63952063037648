import { take, takeUntil, timeout } from 'rxjs/operators';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { UserDataService } from 'src/app/providers/user-data/user-data.service';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { Subscription, Subject, combineLatest, BehaviorSubject, ReplaySubject } from 'rxjs';
import { UpdateVersionService } from 'src/app/providers/update-version/update-version.service';
import { AppUpdate } from 'src/app/models/app-update.interface';
import { environment } from 'src/environments/environment';
import { UpdateVersionModalComponent } from './update-version-modal/update-version-modal.component';
@Component({
  selector: 'app-splash',
  templateUrl: './splash.page.html',
  styleUrls: ['./splash.page.scss'],
})
export class SplashPage implements OnInit {

  @ViewChild('splash', {static: false}) splash: ElementRef;
  userStateSubscription: Subscription;
  splashTimeoutDone: boolean = false;
  userAuthCheckedDone: boolean = false;
  timerOvertime: any = null;

  splashTimedOut$: ReplaySubject<boolean> = new ReplaySubject(1);
  destroy$: Subject<boolean> = new Subject<boolean>();
  updateCheckError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  checksResolved$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  
  constructor(
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private userService: UserDataService,
    private updateService: UpdateVersionService
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    if (Capacitor.isNativePlatform()) {
      StatusBar.hide();
    }

    this.performChecks(8000);
  }

  ionViewDidEnter() {
    SplashScreen.hide();

    setTimeout(() => {
      this.splashTimedOut$.next(true);
    }, 5000);

  }

  ionViewWillLeave() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ionViewDidLeave() {
    if (Capacitor.isNativePlatform()) {
      StatusBar.show();
    }
  }

  onRetryChecks() {
    this.updateCheckError$.next(false);
    this.checksResolved$.next(false);
    this.performChecks(5000);
  }

  private performChecks(timeOutVal: number) {
    combineLatest([this.updateService.checkForUpdate(), this.userService.userAuthStateChecked$, this.splashTimedOut$]).pipe(
      timeout(timeOutVal),
      take(1),
      takeUntil(this.destroy$)
    ).subscribe(results => {
      this.checksResolved$.next(true);
      let updateInfo: AppUpdate = results[0];
      
      if (!updateInfo.allowUsage) {
        this.showUpdateVersionModal(false, false);
      }
      else {
        let currentApplicationVersion = environment.appVersion;
        let splittedCurrentVersion = currentApplicationVersion.split('.');
        let splittedStoreVersion = updateInfo.latestReleaseVersion.split('.');
        if (splittedStoreVersion[0] > splittedCurrentVersion[0]) { //Major version check
          this.showUpdateVersionModal(true, true);
        }
        else if ((splittedStoreVersion[0] == splittedCurrentVersion[0]) && (splittedStoreVersion[1] > splittedCurrentVersion[1])) { //Minor version check
          this.showUpdateVersionModal(true, false);
        }
        else {
          this.clearToProceed();
        }
      }
    }, err => {
      this.updateCheckError$.next(true);
    });

  }

  async showUpdateVersionModal(bAllowUsage: boolean, bRequireUpdate: boolean) {

    const modal = await this.modalCtrl.create({
      component: UpdateVersionModalComponent,
      componentProps: {
        allowUsage: bAllowUsage,
        requireUpdate: bRequireUpdate
      },
      backdropDismiss: false,
      cssClass: ['info-modal', 'info-modal--height_240']
    });

    modal.onWillDismiss().then((res: any) => {
      this.clearToProceed();
    });

    return await modal.present();

  }

  private clearToProceed() {
    
    let accToken = localStorage.getItem('QRAccommodationToken');
    if (accToken) {
      this.navCtrl.navigateRoot(['/agent-details', accToken]);
    }
    else {
      if (this.userService.isAuthenticated()) {
        this.navCtrl.navigateRoot('/global-home');
      }
      else {
        this.navCtrl.navigateRoot('/onboarding');
      }
    }
  }

  

}
