export interface SignupDetails {
  firstname: string;
  lastname: string;
  phone?: {
    country: string;
    number: string;
  };
  email: string;
  password: string;
  agree_terms: boolean;
}

export interface LoginDetails {
  email: string;
  password: string;
  remember_me: boolean;
}

export interface UserProfileDetails {
  firstname: string;
  lastname: string;
  dateOfBirth?: string;
  gender?: string;
  mobile?: string;  
  email: string;
}

export interface UserProfile {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  mobileVerified: boolean;
}

export const EMPTY_USER_PROFILE: UserProfile = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  mobileVerified: false
}