import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, of } from 'rxjs';
import { map, flatMap, filter, toArray, catchError, pluck } from 'rxjs/operators';
import { ShopItem, WorkingHourRange, WorkingHours } from '../../models/shop-item.interface';
import { LocaleService } from '../locale/locale.service';
import { SplashMessage } from 'src/app/models/splash-message.interface';
import { Tag } from 'src/app/models/tag.interface';
import { UserDataService } from '../user-data/user-data.service';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { getDistanceBetweenPoints } from 'src/app/models/utility-functions';
import { ModalController } from '@ionic/angular';
import { HiddenShopModalComponent } from '../../pages/cafe-menu/hidden-shop-modal/hidden-shop-modal.component';
import { FeaturedAdd } from 'src/app/models/featured-add.interface';
import { GetAvailableQTablesResponse } from 'src/app/models/get-available-qtables-response.interface';
import { SectorDto, SectorType } from 'src/app/models/sector-dto.interface';
import { NgHttpCachingHeaders, NgHttpCachingService } from 'ng-http-caching';
import { DeliveryMethod } from "../../pages/order-type/delivery-method-discount-label/delivery-method.enum";
import { ProductsService } from "../products/products.service";
import { SuggestionService } from "../suggestion/suggestion.service";
import { Preferences } from '@capacitor/preferences';
import { addDays, setHours, setMinutes, setSeconds, setMilliseconds, getHours, getMinutes } from 'date-fns';

interface ShopDetailsResponse {
  shop: any;
}

interface ShopsResponse {
  shops: Array<ShopItem>;
}

interface ClearFeaturedShopsResponse {
  clear: any;
}

interface positionFeaturedShopResponse {
  position: any;
}

interface RemoveFeaturedShopResponse {
  unfeatured: any;
}

interface SplashResponse {
  splash: any;
}

interface TagResponse {
  tag: any;
}

@Injectable({
  providedIn: 'root'
})
export class CafesService {

  nearbyCafes: Array<any> = [];
  cafeLocations: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  private _featuredShops: BehaviorSubject<Array<ShopItem>> = new BehaviorSubject([]);
  public readonly featuredShops: Observable<Array<ShopItem>> = this._featuredShops.asObservable();
  public loadingFeaturedShops: boolean = false;
  
  private _splashMessages: BehaviorSubject<Array<SplashMessage>> = new BehaviorSubject([{id: 0, image: 'assets/images/QPass_logo.png' }]);
  public readonly splashMessages: Observable<Array<SplashMessage>> = this._splashMessages.asObservable();
  private readonly cafeServices = ['menuOnlyService', 'orderProgressService', 'deliveryTracking', 'offersService', 'bookingService', 'messagingService', 'agentService']
  
  get featuredShops$(): ShopItem[] {
    return this._featuredShops.getValue()
  }

  constructor(
    private http: HttpClient,
    private localeService: LocaleService,
    private userDataService: UserDataService,
    private logger: NGXLogger,
    private modalCtrl: ModalController,
    private ngHttpCachingService: NgHttpCachingService,
    private productsService: ProductsService,
    private suggestionsService: SuggestionService
  ) {
    
    this.getSplashMessages().subscribe(res => {
      if (res.length === 0) {
        this._splashMessages.next([{id: 0, image: 'assets/images/QPass_logo.png' }]);
      }
      else {
        this._splashMessages.next(res);
      }
    }, err => {
      
    });

    this.userDataService.userToken$.subscribe(token => {
      if (token) {
        Preferences.get({ key: 'QR_shopId' }).then((data) => {
          const shopId = data.value;
          if (shopId) {
            this.addFeaturedShop(+shopId).subscribe(res => {
              if (+shopId == res.featured) {
                Preferences.remove({ key: 'QR_shopId' });
              }
            });
          }
        });
      }
    });

  }

  getSplashMessages(): Observable<Array<SplashMessage>> {
    
    return this.http.get<SplashResponse>(environment.QpassConfiguration.apiUrl + '/splash').pipe(
      map(res => res.splash),
      catchError(this.handleError('providers.cafes.getSplashMessages'))
    );
  }

  getTags(): Observable<Array<Tag>> {
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));
  
    return this.http.get<TagResponse>(environment.QpassConfiguration.apiUrl + '/tags', {params: url_params}).pipe(
      map(res => res.tag),
      catchError(this.handleError('providers.cafes.getTags'))
    );
  }


  getCafeDetails(cafeID, forceUpdate: boolean = false) {
    if (forceUpdate) {
      this.ngHttpCachingService.clearCacheByTag('SHOP_DETAILS');
    }
    
    let headers = new HttpHeaders();
    headers = headers.append(NgHttpCachingHeaders.ALLOW_CACHE, '1');
    headers = headers.append(NgHttpCachingHeaders.TAG, 'SHOP_DETAILS');
    
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('shopId', cafeID);
    url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));
    return this.http.get<ShopDetailsResponse>(environment.QpassConfiguration.apiUrl + "/shops/details", {headers, params: url_params}).pipe(
      map(res => res.shop),
      map(shop => {
        this.fixTimeIfNeeded(shop.workingHours);
        return shop;
      }),
      catchError(this.handleError('providers.cafes.getCafeDetails'))
    );
  }

  getShopDetailsByID(token: string, forceUpdate: boolean = false) {
    if (forceUpdate) {
      this.ngHttpCachingService.clearCacheByTag('SHOP_DETAILS_BY_TOKEN');
    }
    
    let headers = new HttpHeaders();
    headers = headers.append(NgHttpCachingHeaders.ALLOW_CACHE, '1');
    headers = headers.append(NgHttpCachingHeaders.TAG, 'SHOP_DETAILS_BY_TOKEN');
    
    let url_params: HttpParams = new HttpParams();
    // url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));
    return this.http.get<ShopDetailsResponse>(environment.QpassConfiguration.apiUrl + "/shops/" + token, {headers, params: url_params}).pipe(
      map(res => res.shop),
      map(shop => {
        this.fixTimeIfNeeded(shop.workingHours);
        return shop;
      }),
      catchError(this.handleError('providers.cafes.getShopDetailsByToken'))
    );
  }

  getNearbyShops(lat, lng,type?: string,limit?:number): Observable<Array<ShopItem>> {
    
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('latitude', lat);
    url_params = url_params.append('longitude', lng);
    if (type){
      url_params = url_params.append('type', type);
      url_params = url_params.append('limit', limit);
    }
    url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));
    
    // assets/data/featured-cafes.json
    // this.http.get('assets/data/featured-cafes.json', options).delay(2000).map(res => {let body: any = res.json();  return body.shops || { };}).catch(this.handleError).subscribe(cafes => {this.nearbyCafes = cafes; }, error => {this.errorMessage = <any>error; });
    return this.http.get<ShopsResponse>(environment.QpassConfiguration.apiUrl + '/shops/nearby', {params: url_params}).pipe(
      map(res => res.shops),
      map(res => this.computeShopDiscounts(res)),
      catchError(this.handleError('providers.cafes.getNearbyShops'))
    );
  }

  getShopsByPromo(lng, lat, type, page: number = 0, count: number = 20) {
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('longitude', lng);
    url_params = url_params.append('latitude', lat);
    url_params = url_params.append('type', type);
    url_params = url_params.append('page', page); 
    url_params = url_params.append('count', count);

    url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));

    return this.http.get<ShopsResponse>(environment.QpassConfiguration.apiUrl + '/shops/findByPromo', {params: url_params}).pipe(
      map(res => res.shops),
      catchError(this.handleError('providers.cafes.getShopsByPromo'))
    );
  }

  populateFeaturedShops() {
    let token = this.userDataService.getUserToken();
    if (token) {
      this.loadingFeaturedShops = true;
      this.getFeaturedShops().subscribe(featured => {        
        this._featuredShops.next(featured);
      }, err => {
        console.error(err);
      }, () => {
        this.loadingFeaturedShops = false;
      });
    }
  }

  getFeaturedShops(): Observable<Array<ShopItem>> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      let url_params: HttpParams = new HttpParams();
      url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));

      return this.http.get<ShopsResponse>(environment.QpassConfiguration.apiUrl + '/shops/featured', {headers: headers, params: url_params}).pipe(
          map(res => res.shops),
        map(shops => {
          shops.forEach(shop => {
            this.fixTimeIfNeeded(shop.workingHours);
          });
          return shops;
        }),
        map(res => this.computeShopDiscounts(res)),
        catchError(this.handleError('providers.cafes.getFeaturedShops'))
      );
    }
    else {
      return of([]);
    }
  }

  positionFeaturedShop(shopId, positionId): Observable<any>  {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      let url_params: HttpParams = new HttpParams();
      url_params = url_params.append('position', positionId);
      url_params = url_params.append('shopId', shopId);
      headers = headers.append('Authorization', 'Bearer ' + token);
      
      return this.http.get<positionFeaturedShopResponse>(environment.QpassConfiguration.apiUrl + '/shops/featured/position', {headers: headers, params: url_params}).pipe(
        map(res => res.position),
        catchError(this.handleError('providers.cafes.positionFeaturedShop'))
      );
    }
    else {
      return of([]);
    }

  }

  removeAllFeaturedShops(): Observable<any> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      return this.http.get<ClearFeaturedShopsResponse>(environment.QpassConfiguration.apiUrl + '/user/featured/clear', {headers: headers}).pipe(
        map(res => res.clear),
        catchError(this.handleError('providers.shops.removeAllFeaturedShops'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR'));
    }
  }

  addFeaturedShop(shopId): Observable<FeaturedAdd>  {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      let url_params: HttpParams = new HttpParams();
      url_params = url_params.append('shopId', shopId);

      return this.http.get<FeaturedAdd>(environment.QpassConfiguration.apiUrl + '/user/featured/add', {headers: headers, params: url_params}).pipe(
        catchError(this.handleError('providers.cafes.addFeaturedShop'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR'));
    }
  }


  prepareRemoveFeaturedShop(shopData: ShopItem): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.removeFeaturedShop(shopData.id).subscribe(shopId => {
        let featShops = this._featuredShops.getValue();
        let idx = 0;
        for (let cf of featShops) {
          if (cf.id == shopId) {
            featShops.splice(idx, 1);
            break;
          }
          idx++;
        }
        this._featuredShops.next(featShops);
        resolve(true);
      }, err => {
        reject(false);
      })
    });
  }

  removeFeaturedShop(shopId): Observable<any>  {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      let url_params: HttpParams = new HttpParams();
      url_params = url_params.append('shopId', shopId);

      return this.http.get<RemoveFeaturedShopResponse>(environment.QpassConfiguration.apiUrl + '/user/featured/remove', {headers: headers, params: url_params}).pipe(
        map(res => res.unfeatured),
        catchError(this.handleError('providers.cafes.removeFeaturedShop'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR'));
    }
  }

  filterShops(searchTerm:string, lat, lng, sortBy: string, page: number = 0, count: number = 20): Observable<any> {
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('query', searchTerm);
    if (lat) url_params = url_params.append('latitude', lat);
    if (lng) url_params = url_params.append('longitude', lng);
    if (sortBy === 'minimumOrder') {
      url_params = url_params.append('sort', 'minimum_order');
    }
    else if (sortBy === 'deliveryCost') {
      url_params = url_params.append('sort', 'delivery');
    }

    if (page !== null) url_params = url_params.append('page', page);
    if (count > 0) url_params = url_params.append('count', count);

    url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));

    return this.http.get<ShopsResponse>(environment.QpassConfiguration.apiUrl + '/shops/find', {params: url_params}).pipe(
      map(res => res.shops),
      map(res => this.computeShopDiscounts(res)),
      catchError(this.handleError('providers.cafes.filterShops'))
    );
  }

  getShopsByTag(searchTerm:string, lat, lng, sortBy: string, page: number = 0, count: number = 20) {
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('name', searchTerm);
    url_params = url_params.append('latitude', lat);
    url_params = url_params.append('longitude', lng);
    url_params = url_params.append('page', page);
    url_params = url_params.append('count', count);

    if (sortBy === 'minimumOrder') {
      url_params = url_params.append('sort', 'minimum_order');
    }
    else if (sortBy === 'deliveryCost') {
      url_params = url_params.append('sort', 'delivery');
    }

    url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));

    return this.http.get<ShopsResponse>(environment.QpassConfiguration.apiUrl + '/shops/findByTag', {params: url_params}).pipe(
      map(res => res.shops),
      map(res => this.computeShopDiscounts(res)),
      catchError(this.handleError('providers.cafes.filterShops'))
    );
  }

  recomendedShops(lat, lng, limit?): Observable<any> {
    let url_params: HttpParams = new HttpParams();
    if (lat) url_params = url_params.append('latitude', lat);
    if (lng) url_params = url_params.append('longitude', lng);
    if (limit > 0) url_params = url_params.append('limit', limit);
    
    url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));

    return this.http.get<ShopsResponse>(environment.QpassConfiguration.apiUrl + '/shops/recommended', {params: url_params}).pipe(
      map(res => res.shops),
      map(res => this.computeShopDiscounts(res)),
      catchError(this.handleError('providers.cafes.recomendedShops'))
    );

  }

  getShopsInBox(areaBounds, mapCenterLocation, userLocation, limit): Observable<any> {

    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('southWestLatitude', areaBounds.sw.lat);
    url_params = url_params.append('southWestLongitude', areaBounds.sw.lng);
    url_params = url_params.append('northEastLatitude', areaBounds.ne.lat);
    url_params = url_params.append('northEastLongitude', areaBounds.ne.lng);
    if (userLocation) url_params = url_params.append('latitude', userLocation.lat);
    if (userLocation) url_params = url_params.append('longitude', userLocation.lng);
    if (limit > 0) url_params = url_params.append('limit', limit);
    url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));
    
    return this.http.get<ShopsResponse>(environment.QpassConfiguration.apiUrl + '/shops/inBox', {params: url_params}).pipe(
      map(res => this.computeShopDiscounts(res.shops)),
      map((res:any) => {
        return res.filter(cafe => {
          return (cafe.latitude >= areaBounds.sw.lat && cafe.latitude <= areaBounds.ne.lat && cafe.longitude >= areaBounds.sw.lng && cafe.longitude <= areaBounds.ne.lng);
        })
      }),
    );
  }

  getCafeBaristaIsActive(shopId) {
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('shopId', shopId);

    return this.http.get<any>(environment.QpassConfiguration.apiUrl + '/shop/barista/isActive', {params: url_params}).pipe(
      map(res => res.isActive),
      catchError(this.handleError('providers.cafes.getCafeBaristaIsActive'))
    );
  }

  isShopOnlyBooking(cafeShop: any): boolean {
    for(let service of this.cafeServices) {
      if(service != 'bookingService') {
        if(cafeShop[service]) {
          return false;
        }
      }
    }
    return cafeShop['bookingService'] == true;
  }

  isMarkerOpen(marker: any): Observable<boolean> {
    return this.isShopOpen(marker.workingHours, marker.shopId, marker.menuOnlyService);
  }

  isShopOpen(workingHours, shopId, menuOnlyService) {
    if (workingHours) {
      if (!menuOnlyService) {
        return this.getCafeBaristaIsActive(shopId).pipe(
          map((isBaristaActive) => {
            const isDateFeasible = this.isDateFeasible(new Date(), workingHours);
            if (isDateFeasible) {
              const isOpen = this.checkBaristaOnWorkingHours(isDateFeasible, isBaristaActive);
              return isOpen;
            } else {
              return false;
            }
          })
        );
      } else {
        return of(this.isDateFeasible(new Date(), workingHours)); 
      }
    } else {
      return of(true);
    }
  }

  loadAvailableQTables(shopId: number, query?: string): Promise<SectorDto[] | undefined> {
  
      return this.http.get<GetAvailableQTablesResponse>(environment.QpassConfiguration.apiUrl + '/qtable/list', {params: new HttpParams({ fromObject: { shopId: shopId.toString(), ...(query && { query: query } ) }})}).pipe(
              pluck('sectors'),
              map((sectorDtos) => {
                return sectorDtos.filter(sector => sector.type === SectorType.q_table)
              }),
              catchError(this.handleError('providers.shops.loadAvailableQTables'))
            ).toPromise();
  }

  public calculateDistance(sourceLocation, cafe) {

    if (sourceLocation.lat && sourceLocation.lng && cafe.lat && cafe.lng) {
      let placeLocation = {
        lat: cafe.lat,
        lng: cafe.lng
      };

      cafe.distance = getDistanceBetweenPoints(sourceLocation, placeLocation, 'km').toFixed(2) + 'km';
    }
  }

  public isDateFeasible(date: Date, workingHours: WorkingHours) {
    workingHours = this.fixTimeIfNeeded(workingHours)
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const dateDayOfWeek = days[date.getDay()];
    let dateDateTime = date;
    let shopTimeFrom = date;
    let shopTimeTo = date;

    let times: WorkingHourRange[] = [];

    if (dateDayOfWeek !== 'saturday' && dateDayOfWeek !== 'sunday' && workingHours.weekdays?.some((h) => h.from !== '' || h.to !== '')) {
      times = workingHours.weekdays.filter((h) => h.from !== '' || h.to !== '');
    }
    else if ((dateDayOfWeek === 'saturday' || dateDayOfWeek === 'sunday') && workingHours.weekends?.some((h) => h.from !== '' || h.to !== '')) {
      times = workingHours.weekends.filter((h) => h.from !== '' || h.to !== '');
    }
    else {
      const workingDay = workingHours[dateDayOfWeek];
      if (workingDay.some((h) => h.from !== '' || h.to !== '')) {
        times = workingDay.filter((h) => h.from !== '' || h.to !== '');
      }
    }

    let isAvailable: boolean = false;

    for (let i = 0; i < times.length; i++) {
      const time = times[i];
      const timeFromArray = time.from.split(':');
      const timeFromHour = Number(timeFromArray[0]);
      const timeFromMinute = Number(timeFromArray[1]);
      shopTimeFrom = setHours(setMinutes(setSeconds(setMilliseconds(shopTimeFrom, 0), 0), timeFromMinute), timeFromHour);
      const timeToArray = time.to.split(':');
      const timeToHour = Number(timeToArray[0]);
      const timeToMinute = Number(timeToArray[1]);
      shopTimeTo = setHours(setMinutes(shopTimeTo, timeToMinute), timeToHour);
      if (timeToHour <= timeFromHour) {
        shopTimeTo = addDays(shopTimeTo, 1);
        dateDateTime = addDays(dateDateTime, 1);
      }
      shopTimeTo = setHours(setMinutes(setSeconds(setMilliseconds(shopTimeTo, 0), 0), timeToMinute), timeToHour);
      const orderDateHours = getHours(date);
      const orderDateMins = getMinutes(date);
      dateDateTime = setHours(setMinutes(shopTimeTo, orderDateMins), orderDateHours);
      if (dateDateTime > shopTimeFrom && dateDateTime < shopTimeTo) {
        isAvailable = true;
        break;
      } else {
        isAvailable = false;
      }
    }

    return isAvailable;
  }

  private checkBaristaOnWorkingHours(dateFeasible: boolean, isBaristaActive: boolean) {
    if (dateFeasible) {
      return isBaristaActive;
    } else {
      return false;
    }
  }

  private fixTimeIfNeeded(workingHours: any) {
    if(workingHours) {
      Object.keys(workingHours).forEach(function (key, index) {
        let workingHour = workingHours[key];
        if(workingHour && Array.isArray(workingHour)) {
          workingHour.forEach(hour => {
            if (hour.to === "00:00") {
              hour.to = "23:59";
            }
          })
        }
      });
    }
    return workingHours;
  }

  private handleError (operation = 'operation') {
    return (err: HttpErrorResponse) => {
      let errMsg: string = "USER_SRV.ERR";
      
      if (err.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        this.logger.log(`${operation} failed: ${err.error.message}`);
        errMsg = "USER_SRV.ERR_NO_WEB_ACCESS";
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        this.logger.log(`${operation} failed: Backend returned code ${err.status}, body was: ${err.error.error}`);
        errMsg = "USER_SRV.ERR_SERVER_COMM";
      }
      return throwError(errMsg);
    }
  }

  isShopFeatured(shopId: number) {
    return this.getFeaturedShops().pipe(map(featured => {
      return featured.some(item => item.id === shopId);
    }));
  }

  async showCafeHiddenModal() {
    const modal = await this.modalCtrl.create({
      component: HiddenShopModalComponent,
      cssClass: ['info-modal', 'info-modal--top', 'info-modal--height_220']
    });

    modal.onWillDismiss().then((res: any) => {
    });

    return await modal.present();

  }

  isMarkerGreyedOut(marker: any) {
    return this.isMarkerOpen(marker).pipe(map(isShopOpen => {
      return !isShopOpen;
    }));
  }

  async sortShopListBasedOnActive(shops: ShopItem[], sort: string) {
    let newShops = [];
    await shops.forEach((shop) => {
      this.isShopOpen(shop.workingHours, shop.id, shop.menuOnlyService).subscribe(isShopOpen => {
        const newShop = 
        {
          ...shop,
          status: shop.menuOnlyService || isShopOpen ? 'open' : 'closed'
        };
        newShops.push(newShop);
      });
    });

    let newShopsOpen = newShops.filter((shop) => {
      return shop.status === 'open';
    }); 

    let newShopsClosed = newShops.filter((shop) => {
      return shop.status === 'closed';
    });

    newShopsOpen = [...newShopsOpen.sort((a,b) => {
      if (sort === 'nearby') {
        return a.distance - b.distance;
      } else if (sort === 'minimumOrder') {
        return a.minimumOrder - b.minimumOrder;
      } else if (sort === 'deliveryCost') {
        return a.deliveryCharge - b.deliveryCharge;
      }
    })];

    newShopsClosed = [...newShopsClosed.sort((a,b) => {
      if (sort === 'nearby') {
        return a.distance - b.distance;
      } else if (sort === 'minimumOrder') {
        return a.minimumOrder - b.minimumOrder;
      } else if (sort === 'deliveryCost') {
        return a.deliveryCharge - b.deliveryCharge;
      }
    })];

    newShops = [...newShopsOpen, ...newShopsClosed];

    return newShops;
  }

  computeShopDiscounts(shops: any) {
    shops.forEach(shop => {
      shop.shopDiscounts = [];
      if(shop.generalDiscountPercentage) {
        shop.shopDiscounts.push(new ShopDiscount(shop.generalDiscountPercentage, null));
      } else {
        let shopDiscounts: Array<ShopDiscount> = [];
        DeliveryMethod.allValues.forEach(deliveryMethod => {
          if(deliveryMethod.discountField) {
            if(shop[deliveryMethod.discountField] && this.isDeliveryMethodActive(shop, deliveryMethod)) {
              shopDiscounts.push(new ShopDiscount(shop[deliveryMethod.discountField], deliveryMethod.icon));
            }
          }
        })
        shopDiscounts = shopDiscounts.sort((item1, item2) => item2.discount - item1.discount);
        shop.shopDiscounts = shopDiscounts.slice(0, 2)
      }
    });
    return shops;
  }

  isDeliveryMethodActive(shop: any, deliveryMethod: DeliveryMethod) {
    if(!shop[deliveryMethod.shopField]) {
      return false;
    }
    switch (deliveryMethod) {
      case DeliveryMethod.DELIVERY: {
        return shop?.deliveryWorkingHours ? this.isDateFeasible(new Date(), shop.deliveryWorkingHours) : true;
      }
      case DeliveryMethod.TAKE_AWAY: {
        return shop?.workingHours ? this.isDateFeasible(new Date(), shop.workingHours) : true;
      }
      case DeliveryMethod.DRIVE_THRU: {
        return shop?.workingHours ? this.isDateFeasible(new Date(), shop.workingHours) : true;
      }
      default: {
        return true;
      }
    }
  }

  shopHasAgentCategories(shop: any): Observable<boolean> {
    return this.suggestionsService.getCategories(shop.id, false)
        .pipe(
            map(categories => {
              let hasAgentCategory = categories.length > 0;
              return hasAgentCategory && shop.agentService;
            })
        )
  }
}

class ShopDiscount {
  public discount: number;
  public icon: string;


  constructor(discount: number, icon: string) {
    this.discount = discount;
    this.icon = icon;
  }
}
