import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Rating } from '../../models/rating.interface';
import { Events } from '../events/events.service';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { LocaleService } from '../locale/locale.service';
import { UserDataService } from '../user-data/user-data.service';

interface CategoriesResponse {
  categories: any;
}

interface RatingResponse {
  rating: any;
}

interface RatingListResponse {
  ratings: any;
}

interface NewRatingResponse {
  rating: any;
}

@Injectable({
  providedIn: 'root'
})
export class RatingsService {

  constructor(
    private http: HttpClient,
    private events: Events,
    private localeService: LocaleService,
    private userDataService: UserDataService,
    private logger: NGXLogger
  ) {

  }

  getRatingCategories(shopId): Observable<any> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      let url_params: HttpParams = new HttpParams();
      url_params = url_params.append('shopId', shopId);
      url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));
      
      return this.http.get<CategoriesResponse>(environment.QpassConfiguration.apiUrl + '/rating/categories', {headers: headers, params: url_params}).pipe(
        map((res) => {
          let rt: any = {categories: res.categories, comment: '', answer: ''};
          return rt;
        }),
        catchError(this.handleError('providers.ratings.getRatingCategories'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR'));
    }
  }

  getRating(orderId): Observable<Rating> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      let url_params: HttpParams = new HttpParams();
      url_params = url_params.append('orderId', orderId);
      url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));
      
      return this.http.get<RatingResponse>(environment.QpassConfiguration.apiUrl + '/rating/get', {headers: headers, params: url_params}).pipe(
        map(res => res.rating),
        catchError(this.handleError('providers.ratings.getRating'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR'));
    }
  }

  getRatingList(shopId, pageIndex: number = -1, pageCount?: number): Observable<Array<Rating>> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      let url_params: HttpParams = new HttpParams();
      url_params = url_params.append('shopId', shopId);
      if (pageIndex >= 0) url_params = url_params.append('page', pageIndex.toString());
      if (pageCount >= 0) url_params = url_params.append('count', pageCount.toString());

      url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));
      
      return this.http.get<RatingListResponse>(environment.QpassConfiguration.apiUrl + '/rating/list', {headers: headers, params: url_params}).pipe(
        map(res => res.ratings),
        catchError(this.handleError('providers.ratings.getRatingList'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR'));
    }
  }

  deleteRating(orderId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let userToken = this.userDataService.getUserToken();
      if (userToken) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer ' + userToken);

        let url_params: HttpParams = new HttpParams();
        url_params = url_params.append('orderId', orderId);
      
        this.http.get(environment.QpassConfiguration.apiUrl + '/rating/delete', {headers: headers, params: url_params}).subscribe(res => {
          resolve({resultCode: 0, resultDescription: 'RATINGS_SRV.DELETE_SUCCESS'});
        }, err => {
          reject({resultCode: 1, resultDescription: 'RATINGS_SRV.DELETE_ERR'});
        });
      }
      else {
        reject({resultCode: 2, resultDescription: 'USER_SRV.ERR'});
      }
    });
  }

  sendNewRating(orderId: any, ratingContent: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let userToken = this.userDataService.getUserToken();
      if (userToken) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers = headers.append('Authorization', 'Bearer ' + userToken);

        let body = "orderId=" + orderId;

        for (let keyVal in ratingContent.ratings) {
          if (ratingContent.ratings.hasOwnProperty(keyVal)) {
            body += "&" + keyVal + "=" + ratingContent.ratings[keyVal];
          }
        }

        if (ratingContent.comment) {
          body += "&comment=" + ratingContent.comment;
        }
        
        this.http.post<NewRatingResponse>(environment.QpassConfiguration.apiUrl + '/rating/new', body, {headers: headers}).pipe(
          map(res => res.rating)
        ).subscribe(newRating => {
          resolve({resultCode: 0, resultDescription: 'RATINGS_SRV.POST_NEW_SUCCESS', resultData: newRating});
        }, err => {
          reject({resultCode: 1, resultDescription: 'RATINGS_SRV.POST_NEW_ERR'});
        });
      }
      else {
        reject({resultCode: 2, resultDescription: 'USER_SRV.ERR'});
      }
    });
  }

  private toUTF8Array(str) {
    var utf8 = [];
    for (var i=0; i < str.length; i++) {
        var charcode = str.charCodeAt(i);
        if (charcode < 0x80) utf8.push(charcode);
        else if (charcode < 0x800) {
            utf8.push(0xc0 | (charcode >> 6), 
                      0x80 | (charcode & 0x3f));
        }
        else if (charcode < 0xd800 || charcode >= 0xe000) {
            utf8.push(0xe0 | (charcode >> 12), 
                      0x80 | ((charcode>>6) & 0x3f), 
                      0x80 | (charcode & 0x3f));
        }
        // surrogate pair
        else {
            i++;
            // UTF-16 encodes 0x10000-0x10FFFF by
            // subtracting 0x10000 and splitting the
            // 20 bits of 0x0-0xFFFFF into two halves
            charcode = 0x10000 + (((charcode & 0x3ff)<<10)
                      | (str.charCodeAt(i) & 0x3ff))
            utf8.push(0xf0 | (charcode >>18), 
                      0x80 | ((charcode>>12) & 0x3f), 
                      0x80 | ((charcode>>6) & 0x3f), 
                      0x80 | (charcode & 0x3f));
        }
    }
    return utf8;
  }


  private handleError (operation = 'operation') {
    return (err: HttpErrorResponse) => {
      let errMsg: string = "USER_SRV.ERR";
      
      if (err.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        this.logger.log(`${operation} failed: ${err.error.message}`);
        errMsg = "USER_SRV.ERR_NO_WEB_ACCESS";
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        this.logger.log(`${operation} failed: Backend returned code ${err.status}, body was: ${err.error}`);
        errMsg = "USER_SRV.ERR_SERVER_COMM";
      }
      return throwError(errMsg);
    }
  }

}
