<ion-header>
  <ion-grid class="ion-no-padding">
    <ion-row class="info-modal-header" style="margin: 24px 24px 12px 24px;">
      <ion-col>
        <h4 class="ion-no-margin qpass-color-text-alt">
          {{'REJECTED_ORDER_MODAL.REJECTED_HEADER' | translate : {id:orderInfo.id} }}
        </h4>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-header>
<ion-content *ngIf="orderInfo.rejectionReason">
  <ion-grid class="ion-no-padding">
    <ion-row class="info-modal-body">
      <ion-col>
        <ion-list lines="none">
          <ion-item lines="none" class="qpass-cart-item-header">
            <ion-label class="ion-no-margin qpass-text-body-alt-normal">
              {{"MY_ORDERS_DETAILS.REJECTION_REASON_LABEL" | translate}}:
            </ion-label>
          </ion-item>
          <ion-item lines="none" class="qpass-cart-item-footer">
            <ion-label class="ion-no-margin qpass-text-body-main-normal cancellation-message-wrap">
              {{orderInfo.rejectionReason}}
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-grid class="ion-no-padding">
    <ion-row *ngIf="showGoToOrder">
      <ion-col>
        <ion-button color="qsecondary" class="ion-no-margin" expand="full" fill="clear" size="large" (click)="goToOrder()">
          {{'REJECTED_ORDER_MODAL.OPEN_ORDER' | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button color="primary" class="ion-no-margin" expand="full" fill="solid" (click)="dismiss()">
          {{'APP.GOT_IT' | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
  