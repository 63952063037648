import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { Network } from '@capacitor/network';
import { PluginListenerHandle } from '@capacitor/core';
import { TravelTimeEstimation } from 'src/app/models/travel-time.interface';
import { MapBounds, MapPoint } from 'src/app/models/map-point.interface';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {

  autocompleteService: any;
  directionsService: any;
  detailsService: any;
  geocoderService: any;
  private _mapsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public mapsLoaded$: Observable<boolean> = this._mapsLoaded$.asObservable();
  private networkHandler:PluginListenerHandle = null;
  private _placePredictions: Subject<Array<any>> = new Subject();
  placePredictions: Observable<any> = this._placePredictions.asObservable();

  constructor(
    private zone: NgZone,
    private logger: NGXLogger
  ) {

      this.init();
    
  }

  init() {
    this.loadGoogleMapsServices().then((res) => {
      this.logger.log("GOOGLE SERVICES LOADED");
      this._mapsLoaded$.next(true);
    }, (err) => {
      this.logger.log(err);
    });
  }


  loadGoogleMapsServices(): Promise<any> {

    return new Promise((resolve, reject) => {

      this.loadSDK().then((res) => {
        resolve(true);
      }, (err) => {
        reject(err);
      });

    });

  }


  private loadSDK(): Promise<any> {

    return new Promise((resolve, reject) => {

      if (!this._mapsLoaded$.getValue()) {

        Network.getStatus().then((status) => {

          if(status.connected){
            new Loader({
              apiKey: environment.googleMapsConfiguration.apiKey,
              libraries: ["places"]
            }).load().then((google) => {
              this.autocompleteService = new google.maps.places.AutocompleteService();
              this.directionsService = new google.maps.DirectionsService();
              this.detailsService = new google.maps.places.PlacesService(document.createElement('div'));
              this.geocoderService = new google.maps.Geocoder();
              resolve(true);
            }, (err) => {
              reject(err);
            });

          } else {

            if(this.networkHandler == null){

              Network.addListener('networkStatusChange', (status) => {

                if(status.connected){
                  this.networkHandler && this.networkHandler.remove();

                  this.loadGoogleMapsServices().then((res) => {
                    this.logger.log("Google Maps ready.")
                  }, (err) => {
                    this.logger.log(err);
                  });
                }
              }).then(val => this.networkHandler = val);

            }

            reject('Not online');
          }

        }, (err) => {
          reject('Network Status not supported.');
        });

      } else {
        reject('SDK already loaded');
      }

    });

  }

  getReverseGeocoding(lat: any, lng: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let me = this;
      const latlng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      };
      if (this.geocoderService) {
        this.geocoderService.geocode({'location': latlng}, (results:any, status:any) => {
          me.zone.run(function () {
            if (status == google.maps.GeocoderStatus.OK) {
              resolve(results);
            } else {
              reject([]);
            }
          });
        });
      }
      else {
        reject([]);
      }
    });
  }

  getGeocoding(address: any, componentRestrictions: any = null): Promise<any> {
    return new Promise((resolve, reject) => {
      let me = this;
      if (this.geocoderService) {
        this.geocoderService.geocode({'address': address, componentRestrictions: componentRestrictions}, (results:any, status:any) => {
          me.zone.run(function () {
            if (status == google.maps.GeocoderStatus.OK) {
              resolve(results);
            } else {
              resolve([]);
            }
          });
        });
      }else {
        resolve([]);
      }
    });
  }

  getTravelTime(origin: MapPoint, destination: MapPoint, mode: string): Promise<TravelTimeEstimation> {

    return new Promise((resolve, reject) => {

      if (this.directionsService) {

        let gMode = google.maps.TravelMode.DRIVING;

        if (mode === 'drive') {
          gMode = google.maps.TravelMode.DRIVING;
        }
        else if (mode === 'walk') {
          gMode = google.maps.TravelMode.WALKING;
        }
        else if (mode === 'bicycle') {
          gMode = google.maps.TravelMode.BICYCLING;
        }

        var request = {
          origin      : origin,
          destination : destination,
          travelMode  : gMode
        };

        let me = this;
    
        this.directionsService.route(request, function(response, status) {
          me.zone.run(function () {
            if ( status == google.maps.DirectionsStatus.OK ) { 
              resolve({ provider: 'google', status: "OK", eta: response.routes[0].legs[0].duration.value, origin: {lat: origin.lat, lng: origin.lng}, destination: {lat: destination.lat, lng: destination.lng} });
            }
            else {
              let obj: TravelTimeEstimation = { provider: 'google', eta: 0, status: status, origin: { lat: origin.lat, lng: origin.lng}, destination: { lat: destination.lat, lng: destination.lng} };
              reject(obj);
            }
          });
        });
      }
      else {
        this.loadGoogleMapsServices();
        let obj: TravelTimeEstimation = { provider: 'google', eta: 0, status: 'GOOGLE_UNDEF', origin: { lat: origin.lat, lng: origin.lng}, destination: { lat: destination.lat, lng: destination.lng} };
        reject(obj);
      }

    });
  }

  getPlacePredictions(query): Promise<any> {
    
    return new Promise((resolve, reject) => {

      
      if (this.autocompleteService) {
        let config = {
          input: query,
          types:  ['geocode'], // other types available in the API: 'establishment', 'regions', and 'cities' 
          componentRestrictions: {  } 
        };
        
        let me = this;
        this.autocompleteService.getPlacePredictions(config, (predictions, status) => {
          me.zone.run(function () {
            resolve(predictions);
          });

        });
      }
      else {
        this.loadGoogleMapsServices();
        reject([]);
      }
    });
  }


  getPlaceDetails(place_id): Promise<any> {

    return new Promise((resolve, reject) => {

      if (this.detailsService) {

        var request = {
          placeId: place_id
        };
        let me = this;
        
        this.detailsService.getDetails(request, (place, status) => {

          if (status == google.maps.places.PlacesServiceStatus.OK) {
            resolve(place);
          }
          else {
            this.loadGoogleMapsServices();
            reject({});
          }
        });

      }
      else {
        reject({});
      }

    });

  }

  createStaticMapUrl(markers: Array<any>, width:number, height:number, isDark:boolean):string {
    let markersUrlPart:string = "";
    markers.forEach((itm) => {
      markersUrlPart += `&markers=color:0x${itm.color}|${itm.lat},${itm.lng}`;
    });
    
    let strStyle:string = "";
    if (isDark) {
      strStyle = "&style=feature:administrative|element:geometry|visibility:off" +
      "&style=feature:administrative.land_parcel|element:labels|visibility:off" +
      "&style=feature:poi|visibility:off" +
      "&style=element:geometry|color:0x242f3e" +
      "&style=element:labels.text.stroke|color:0x242f3e" +
      "&style=element:labels.text.fill|color:0x746855" +
      "&style=feature:administrative.locality|element:labels.text.fill|color:0xd59563" +
      "&style=feature:road|element:geometry|color:0x38414e" +
      "&style=feature:road|element:geometry.stroke|color:0x212a37" +
      "&style=feature:road|element:labels.text.fill|color:0x9ca5b3" +
      "&style=feature:road.highway|element:geometry|color:0x746855" +
      "&style=feature:road.highway|element:geometry.stroke|color:0x1f2835" +
      "&style=feature:road.highway|element:labels.text.fill|color:0xf3d19c" + 
      "&style=feature:road|element:labels.icon|visibility:off" +
      "&style=feature:road.local|element:labels|visibility:off" +
      "&style=feature:transit|visibility:off" +
      "&style=feature:water|element:geometry|color:0x17263c" +
      "&style=feature:water|element:labels.text.fill|color:0x515c6d" +
      "&style=feature:water|element:labels.text.stroke|color:0x17263c" +
      "&style=feature:landscape.man_made|element:geometry.fill|color:0x1e1a1a";
    }
    else {
      strStyle = "&style=feature:administrative|element:geometry|visibility:off" +
      "&style=feature:administrative.land_parcel|element:labels|visibility:off" +
      "&style=feature:poi|visibility:off" +
      "&style=feature:road|element:labels.icon|visibility:off" +
      "&style=feature:road.local|element:labels|visibility:off" +
      "&style=feature:transit|visibility:off" +
      "&style=feature:landscape.man_made|element:geometry.fill|color:0xf8ebe4";
    }

    return `https://maps.googleapis.com/maps/api/staticmap?${encodeURI(markersUrlPart)}${encodeURI(strStyle)}&size=${width}x${height}&region=GR&maptype=roadmap&key=${environment.googleMapsConfiguration.apiKey}`;
  }

  public static calculateZoomFromBounds(bounds: MapBounds, mapWidthInPixels: number, mapHeightInPixels: number): {center: MapPoint; zoomLevel: number} {

    let GLOBE_WIDTH = 256; // a constant in Google's map projection
    let GLOBE_HEIGHT = 256; // a constant in Google's map projection
    let LngDiff = bounds.northeast.lng - bounds.southwest.lng;
    let LatDiff = bounds.northeast.lat - bounds.southwest.lat;
    const MIN_ZOOM: number = 7;
    let zoom: number = 14; // default value to use for signle point
    
    if (LngDiff !== 0 || LatDiff !== 0) { // if not a single point
      if (LngDiff !== 0) {
        if (LngDiff < 0) {
          LngDiff += 360;
        }
        zoom = Math.round(Math.log(mapWidthInPixels * 360 / LngDiff / GLOBE_WIDTH) / Math.LN2);
      }
      else if (LatDiff !== 0) {
        if (LatDiff < 0) {
          LatDiff += 180;
        }
        zoom = Math.round(Math.log(mapHeightInPixels * 180 / LatDiff / GLOBE_HEIGHT) / Math.LN2);
      }
      zoom--; //Subtract one level to be sure
      zoom = Math.max(zoom, MIN_ZOOM);
    }
    
    let center: MapPoint = {lat: (bounds.northeast.lat + bounds.southwest.lat)/2, lng: (bounds.northeast.lng + bounds.southwest.lng)/2};

    return {center: center, zoomLevel: zoom};
  }

}
