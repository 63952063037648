import { Observable, of, throwError, timer } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError, takeWhile, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { UserDataService } from "./user-data/user-data.service";
import { PersonalDataSettingsResponse } from "../models/personal-data-settings-response";
import { PersonalDataShopSettings } from '../models/personal-data-shop-settings.interface';

interface updatePersonalDataResponse {
  settings: any;
}


interface sendEmailResponse {
  data: any;
}


interface deleteAccountResponse {
  delete: any;
}



@Injectable({
  providedIn: 'root'
})
export class PersonalDataSettingsService {
  constructor(
    private toastController: ToastController,
    private userDataService: UserDataService,
    private http: HttpClient,
    private logger: NGXLogger,
    private translateService: TranslateService,
  ) {

  }

  async getShopPersonalDataSettings(shopId: number): Promise<PersonalDataShopSettings> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      return await this.http
        .get<any>(
          environment.QpassConfiguration.apiUrl + '/user/settings/get',
          { headers: headers, params: { shopId: shopId.toString() } }
        )
        .pipe(
          map((res) => res.settings),
          catchError(
            this.handleError(
              'providers.personal-data-settings.getShopPersonalDataSettings'
            )
          ),
          take(1)
        ).toPromise();
    }
  }

  async setPersonalDataShopSettings(shopId: number, settings: PersonalDataShopSettings): Promise<void> {
    const {
      inAppMessages, marketingMaterials
    } = settings;

    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      return await this.http
        .post<updatePersonalDataResponse>(
          environment.QpassConfiguration.apiUrl +
            '/user/settings/set?' +
            `shopId=${shopId}&inAppMessages=${inAppMessages}&marketingMaterials=${marketingMaterials}`,
          null,
          { headers: headers }
        )
        .pipe(
          map((response: any) => {
            return response;
          }),
          catchError(
            this.handleError(
              'providers.personal-data-settings.setPersonalDataShopSettings'
            )
          )
        )
        .toPromise();
    }
  }

  getPersonalDataSettings(): Observable<PersonalDataSettingsResponse> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);
      return this.http.get<any>(environment.QpassConfiguration.apiUrl + '/user/settings/get', { headers: headers }).pipe(
        map(res => res.settings),
        catchError(this.handleError('providers.personal-data-settings.getPersonalDataSettings'))

      );
    }
  }

  setPersonalDataSettings(data: any): Observable<updatePersonalDataResponse> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);
      return this.http.post<updatePersonalDataResponse>(environment.QpassConfiguration.apiUrl + '/user/settings/set?' +
        'serviceProvisioning=' + data.serviceProvisioning +
        "&driveThroughTracking=" + data.driveThroughTracking +
        "&emailUpdates=" + data.emailUpdates +
        "&emailReceipts=" + data.emailReceipts +
        "&smsUpdates=" + data.smsUpdates +
        "&corporateUpdates=" + data.corporateUpdates, null,
        { headers: headers }).pipe(
          map(
            (response: any) => {
              return response;
            }
          ),
          catchError(this.handleError('providers.personal-data-settings.setPersonalDataSettings'))
        )
    }
  }

  sendEmailToUser(): Observable<sendEmailResponse> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);
      return this.http.get<sendEmailResponse>(environment.QpassConfiguration.apiUrl + '/user/data/get', { headers: headers }).pipe(
        map(res => res),
        catchError(this.handleError('providers.personal-data-settings.sendEmailToUser'))
      );
    }
  }

  deleteMyAccount(): Observable<deleteAccountResponse> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);
      return this.http.get<deleteAccountResponse>(environment.QpassConfiguration.apiUrl + '/user/data/delete', { headers: headers }).pipe(
        map(res => res),
        catchError(this.handleError('providers.personal-data-settings.deleteMyAccount'))
      );
    }

  }

  async presentToastWithOptions(status, msgSuccess) {
    this.translateService.get([msgSuccess]).subscribe(trans => {
      let message;
      if (status == 'OK') {
        message = trans[msgSuccess];
      }
      this.toastController.create({
        message: message,
        position: 'bottom',
        duration: status == 'OK' ? 5000 : 4000,
      }).then(toastEl => {
        toastEl.present();
      });

    });
  }

  private handleError(operation = 'operation') {
    return (err: HttpErrorResponse) => {
      let errMsg: string = "USER_SRV.ERR";

      if (err.error instanceof Error) {
        this.logger.log(`${operation} failed: ${err.error.message}`);
        errMsg = "USER_SRV.ERR_NO_WEB_ACCESS";
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        this.logger.log(`${operation} failed: Backend returned code ${err.status}, body was: ${err.error}`);
        errMsg = "USER_SRV.ERR_SERVER_COMM";
      }
      return throwError(errMsg);
    }
  }



}
