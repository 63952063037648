<ion-content scrollY="false">
  <ion-grid class="ion-no-padding">
    <ion-row class="info-modal-header">
      <ion-col>
        <h4 *ngIf="!allowUsage; else allowedUsageTitleTmpl" class="ion-no-margin qpass-color-text-alt">
          {{'UPDATE_VERSION_MODAL.USAGE_ALLOWED_TITLE' | translate }}
        </h4>
        <ng-template #allowedUsageTitleTmpl>
          <h4 *ngIf="requireUpdate" class="ion-no-margin qpass-color-text-alt">
            {{'UPDATE_VERSION_MODAL.MAJOR_UPDATE_TITLE' | translate }}
          </h4>
          <h4 *ngIf="!requireUpdate" class="ion-no-margin qpass-color-text-alt">
            {{'UPDATE_VERSION_MODAL.MINOR_UPDATE_TITLE' | translate }}
          </h4>
        </ng-template>
      </ion-col>
    </ion-row>

    <ion-row class="info-modal-body">
      <ion-col *ngIf="allowUsage; else notAllowedUsageTmpl">
        <div *ngIf="requireUpdate" class="qpass-text-body-alt-slim">{{'UPDATE_VERSION_MODAL.MAJOR_UPDATE_TXT' | translate}}</div>
        <div *ngIf="!requireUpdate" class="qpass-text-body-alt-slim">{{'UPDATE_VERSION_MODAL.MINOR_UPDATE_TXT' | translate}}</div>
      </ion-col>
      <ng-template #notAllowedUsageTmpl>
        <ion-col>
          <div class="qpass-text-body-alt-slim">{{'UPDATE_VERSION_MODAL.USAGE_ALLOWED_TXT' | translate}}</div>
        </ion-col>
      </ng-template>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer *ngIf="allowUsage">
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col>
        <ion-button *ngIf="!requireUpdate" color="qsecondary" class="ion-no-margin" expand="full" fill="clear" size="large" (click)="onClose()">
          {{'APP.CLOSE' | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button color="primary" class="ion-no-margin" expand="full" fill="solid" (click)="onUpdate()">
          {{'APP.UPGRADE' | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>

