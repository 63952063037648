import { Observable, BehaviorSubject, throwError, of, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import firebase from 'firebase/compat/app';
import { environment } from 'src/environments/environment';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import { Capacitor } from '@capacitor/core';
import { NGXLogger } from 'ngx-logger';
import { UserInfoRest } from 'src/app/models/user-info-rest.interface';
import { CheckSmsResponse, SentSmsResponse } from 'src/app/models/phone-verification.interface';
import { datadogLogs } from '@datadog/browser-logs';
import { EMPTY_USER_PROFILE, SignupDetails, UserProfile, UserProfileDetails } from 'src/app/models/auth.interface';
import { UserInfo } from 'src/app/models/user-info.interface';
import { NavController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Device } from '@capacitor/device';

interface UserInfoResponse {
  user: UserInfoRest;
}

export interface UserTokenResponse {
  token: string;
  isNew?: boolean;
}

export interface AnonymousUserTokenResponse {
  id: number;
  bearer: string;
  token: string;
  firebaseUuid?: string;
  uuid?: string;
  fullName?: string;
  email?: string;
  phone?: string;
  language?: string;
  photoUrl?: string;
  image?: string;
  orders?: number;
  totalSpend?: string;
  createdAt?: string;
  lastLogin?: string;


}

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  currentUser: any;
  public userAuthStateChecked$: ReplaySubject<boolean> = new ReplaySubject(1);
  public userApplicationTokenChecked$: ReplaySubject<boolean> = new ReplaySubject(1);

  private _userProfile$: BehaviorSubject<UserProfile | null> = new BehaviorSubject<UserProfile | null>(null);
  public readonly userProfile$: Observable<UserProfile | null> = this._userProfile$.asObservable();

  private _userIsLoggedIn$ = new BehaviorSubject<boolean>(false);
  userIsLoggedIn$ = this._userIsLoggedIn$.asObservable();

  private _userIsVerified$ = new BehaviorSubject<boolean>(false);
  userIsVerified$ = this._userIsVerified$.asObservable();

  private _userIsAuthenticated$ = new BehaviorSubject<boolean>(false);
  userIsAuthenticated$ = this._userIsAuthenticated$.asObservable();

  private _userToken$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public readonly userToken$: Observable<string | null> = this._userToken$.asObservable();

  private _anonymousUserId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public readonly anonymousUserId$: Observable<string | null> = this._anonymousUserId$.asObservable();

  userProfileRef: any;
  
  constructor(
    private http: HttpClient,
    private afAuth: AngularFireAuth,
    private afDB: AngularFireDatabase,
    private afStore: AngularFireStorage,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private translateService: TranslateService,
    private logger: NGXLogger
  ) {

    this.currentUser = this.afAuth.currentUser;
    
    this.afAuth.authState.subscribe((user) => {
      this.onUserChanged(user);
    });

    this.anonymousUserId$.subscribe((anonymousId: string | null) => {
      if (anonymousId) {
        this.getAnonymousUserToken(anonymousId).pipe(
          tap(async(token) => {
            this.logger.info(`Using Anonymous user with Id: ${anonymousId}`, {token: token});
          })
        ).subscribe(token => {
          this._userToken$.next(token);
        });
      }
    });
  }

  onUserChanged(user) {
    this.currentUser = user;
    this.userAuthStateChecked$.next((user === null));
    
    if (user) {

      this._userIsLoggedIn$.next(true);
      this._userIsAuthenticated$.next(!user.isAnonymous);
      this._userIsVerified$.next(user.emailVerified);
      datadogLogs.logger.info('User logged in', {user: user});
      
      // if (user.emailVerified) {
        let anonymousId: string | null = this._anonymousUserId$.getValue();
        this._anonymousUserId$.next(null);
        this.getApplicationToken(user.uid, anonymousId).pipe(
          tap(async(data) => {
            this.logger.info(`Application token acquired for ${user.email}`, {token: data.token});
            this._userToken$.next(data.token);
            this.userApplicationTokenChecked$.next(true);
          })
        ).subscribe(data => {
          if (data.isNew) {
            // user just created on backend
            // populate info from firebase
            this.afDB.database.ref(`/userData/${user.uid}/profile`).once('value', (snapshot) => {
              let prof = snapshot.val();
              if (prof) {
                let profileDetails: UserProfileDetails = {
                  firstname: prof.firstName,
                  lastname: prof.lastName,
                  mobile: prof.mobile,
                  email: prof.email
                } 
                
                this.updateUserInfo(profileDetails).subscribe((res) => {
                  // now backend has been updated
                  // it is safe to remove duplicate data
                  // leave only email
                  this._userProfile$.next({
                    firstName: res.firstName,
                    lastName: res.lastName,
                    mobile: res.phone,
                    email: res.email,
                    mobileVerified: res.verified
                  });

                  this.afDB.database.ref(`/userData/${user.uid}/profile`).update({firstName: "", lastName: "", mobile: ""});
                }, err => {
                  this.logger.error(`Error updating user info for user with id ${user.uid}`, {userEmail: user.email});
                });
              }
              else {
                this.logger.error(`Profile data not found for user with id ${user.uid}`, {userEmail: user.email});
              }
            });
          }
          else {
            this.getUserInfo().subscribe(userInfo => {
              this._userProfile$.next({
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                mobile: userInfo.phone,
                email: userInfo.email,
                mobileVerified: userInfo.verified
              });
            }, err => {
              this.logger.error(`Error recalling user info for user with id  ${user.uid}`, {userEmail: user.email});
            });
          }
        });
      // }
      // else {
      //   // get profile data from firebase
      //   this.afDB.database.ref(`/userData/${user.uid}/profile`).once('value', (snapshot) => {
      //     let prof = snapshot.val();
      //     if (prof) {
      //       this._userProfile$.next({
      //         firstName: prof.firstName,
      //         lastName: prof.lastName,
      //         mobile: prof.phone,
      //         email: prof.email,
      //         mobileVerified: false
      //       });
      //     }
      //     else {
      //       this.logger.error(`Profile data not found for user with id ${user.uid}`, {userEmail: user.email});
      //     }
      //   });
      // }
    }
    else {
      
      this._userIsLoggedIn$.next(false);
      this._userIsAuthenticated$.next(false);
      this._userIsVerified$.next(false);
      this._userProfile$.next(null);
      this._userToken$.next(null);
      this.userApplicationTokenChecked$.next(false);

      Device.getId().then(val => {
        this._anonymousUserId$.next(val.identifier);
      });
    }
  }

  checkProfilePic(): Promise<any> {
    
    return new Promise((resolve, reject) => {
      if (this.currentUser.hasOwnProperty('providerData') && Array.isArray(this.currentUser.providerData) && this.currentUser.providerData.length > 0 && this.currentUser.providerData[0].hasOwnProperty('photoURL')) {
        if(this.currentUser.photoURL !== this.currentUser.providerData[0].photoURL) {
          
          this.currentUser.updateProfile({ photoURL: this.currentUser.providerData[0].photoURL }).then(() => {
            resolve(true);
          }, err => {
            reject(err);
          });
  
        }
        else {
          resolve(false);
        }
      }
      else {
        resolve(false);
      }
    });

  }

  signup(signupDetails: SignupDetails):Promise<any> {

    return new Promise((resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(signupDetails.email, signupDetails.password).then( data => {
        
        let displayName: string = '';
        if (signupDetails.firstname) displayName = signupDetails.firstname;
        if (signupDetails.lastname) {
          if (displayName !== '') displayName += ' ' + signupDetails.lastname;
          else displayName = signupDetails.lastname;
        }

        let photoURL = environment.QpassConfiguration.serverUrl + '/uploads/user/profile_avatar.png';

        let userProfile: UserProfile = EMPTY_USER_PROFILE;
        userProfile.firstName = ((signupDetails.firstname) ? signupDetails.firstname : '');
        userProfile.lastName = ((signupDetails.lastname) ? signupDetails.lastname : '');
        userProfile.mobile = (signupDetails.phone.number ? signupDetails.phone.country + signupDetails.phone.number : '');
        userProfile.email = signupDetails.email;
        this.afDB.database.ref(`/userData/${data.user.uid}/profile`).set(userProfile);

        data.user.updateProfile({
          displayName: displayName,
          photoURL: photoURL
        }).then(() => {
          resolve({resultCode: 0, resultDescription: 'USER_SRV.SUCCESS', data: data.user});
        }).catch((error) => {
          reject({resultCode: 5, resultDescription: 'USER_SRV.ERR'});
        });
        
      }).catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/email-already-in-use') {
          reject({resultCode: 1, resultDescription: 'USER_SRV.ERR_EMAIL_EXISTS'});
        }
        else if (errorCode == 'auth/invalid-email') {
          reject({resultCode: 2, resultDescription: 'USER_SRV.ERR_EMAIL_INVALID'});
        }
        else if (errorCode == 'auth/weak-password') {
          reject({resultCode: 3, resultDescription: 'USER_SRV.ERR_PASS_WEAK'});
        }
        else {
          reject({resultCode: 4, resultDescription: 'USER_SRV.ERR_AUTH'});
        } 
      });
    });
  }

  nativeFacebookLogin(): Promise<any> {
    return new Promise((resolve, reject) => {
      FacebookLogin.logout().then(() => {
        FacebookLogin.login({permissions: ['email', 'public_profile']}).then((response: FacebookLoginResponse) => {

          const facebookCredential = firebase.auth.FacebookAuthProvider.credential(response.accessToken.token);

          this.afAuth.signInWithCredential(facebookCredential).then((data) => {
            if ('additionalUserInfo' in data) {
              if (data.additionalUserInfo.isNewUser) {
                let dbRef = this.afDB.database.ref(`/userData/${data.user.uid}/profile`);

                if ('first_name' in data.additionalUserInfo.profile) {
                  let prof: any = data.additionalUserInfo.profile;
                  dbRef.update({firstName: prof.first_name});
                }

                if ('last_name' in data.additionalUserInfo.profile) {
                  let prof: any = data.additionalUserInfo.profile;
                  dbRef.update({lastName: prof.last_name});
                }

                if ('picture' in data.additionalUserInfo.profile) {
                  let picData: any = data.additionalUserInfo.profile['picture'];
                  if ('data' in picData && 'url' in picData.data) {
                    data.user.updateProfile({photoURL: picData.data.url});
                  }
                }
              }
            }
            resolve({resultCode: 0, resultDescription: 'USER_SRV.SUCCESS', data: data.user});
          }).catch((error) => {
            this.handleFacebookErrors(error, reject);
          });

        }).catch((error) => {
          this.logger.log(error);
          reject({resultCode: 30, resultDescription: 'USER_SRV.ERR'});
        });
      }).catch((error) => {
        this.logger.log(error);
        reject({resultCode: 31, resultDescription: 'USER_SRV.ERR'});
      });
    });
  }

  webFacebookLogin(): Promise<any> {
    
    return new Promise((resolve, reject) => {
      const provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.signInWithPopup(provider).then((data: any) => {

        if ('additionalUserInfo' in data) {
          if (data.additionalUserInfo.isNewUser) {
            let dbRef = this.afDB.database.ref(`/userData/${data.user.uid}/profile`);

            if ('first_name' in data.additionalUserInfo.profile) {
              let prof:any = data.additionalUserInfo.profile;
              dbRef.update({firstName: prof.first_name});
            }

            if ('last_name' in data.additionalUserInfo.profile) {
              let prof:any = data.additionalUserInfo.profile;
              dbRef.update({lastName: prof.last_name});
            }

            if ('picture' in data.additionalUserInfo.profile) {
              let picData:any = data.additionalUserInfo.profile['picture'];
              if ('data' in picData && 'url' in picData.data) {
                data.user.updateProfile({ photoURL: picData.data.url });
              }
            }
          }
        }

        resolve({resultCode: 0, resultDescription: 'USER_SRV.SUCCESS', data: data.user});

      }).catch((error) => {
        if(error.code) {
          this.handleFacebookErrors(error, reject);
        } else {
          this.logger.log(error);
          reject({resultCode: 30, resultDescription: 'USER_SRV.ERR'});
        }
      });

    });
  }

  nativeGoogleLogin(): Promise<any> {
    
    return new Promise((resolve, reject) => {
      GoogleAuth.signIn().then(res => {
        const credential = firebase.auth.GoogleAuthProvider.credential(res.authentication.idToken);
      
        this.afAuth.signInWithCredential(credential).then((data) => {
          if ('additionalUserInfo' in data) {
            if (data.additionalUserInfo.isNewUser && 'profile' in data.additionalUserInfo) {
              let dbRef = this.afDB.database.ref(`/userData/${data.user.uid}/profile`);
  
              if ('given_name' in data.additionalUserInfo.profile) {
                let prof:any = data.additionalUserInfo.profile;
                dbRef.update({firstName: prof.given_name});
              }
  
              if ('family_name' in data.additionalUserInfo.profile) {
                let prof:any = data.additionalUserInfo.profile;
                dbRef.update({lastName: prof.family_name});
              }
  
              if ('picture' in data.additionalUserInfo.profile) {
                data.user.updateProfile({ photoURL: data.additionalUserInfo?.profile.picture as string });
              }
            }
          }
          
          resolve({resultCode: 0, resultDescription: 'USER_SRV.SUCCESS', data: data.user});

        }).catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          if (errorCode == 'auth/user-disabled') {
            reject({resultCode: 11, resultDescription: 'USER_SRV.ERR_USER_DISABLED_SOCIAL'});
          }
          else if (errorCode == 'auth/account-exists-with-different-credential') {
            reject({resultCode: 12, resultDescription: 'USER_SRV.ERR_EMAIL_EXISTS'});
          }
          else if (errorCode == 'auth/wrong-password') {
            reject({resultCode: 13, resultDescription: 'USER_SRV.ERR_PASS_WRONG_SOCIAL'});
          }
          else if (errorCode == 'auth/user-not-found') {
            reject({resultCode: 14, resultDescription: 'USER_SRV.ERR_USER_NOT_FOUND_SOCIAL'});
          }
          else if (errorCode == 'auth/invalid-credential') {
            reject({resultCode: 15, resultDescription: 'USER_SRV.ERR_CRED_INVALID'});
          }
          else if (errorCode == 'auth/operation-not-allowed') {
            reject({resultCode: 16, resultDescription: 'USER_SRV.ERR_OPER_NOT_ALLOWED'});
          }
          else if (errorCode == 'auth/invalid-verification-code') {
            reject({resultCode: 17, resultDescription: 'USER_SRV.ERR_VER_CODE_INVALID'});
          }
          else if (errorCode == 'auth/invalid-verification-id') {
            reject({resultCode: 18, resultDescription: 'USER_SRV.ERR_VER_ID_INVALID'});
          }
          else {
            this.logger.log(errorMessage);
            reject({resultCode: 19, resultDescription: 'USER_SRV.ERR'});
          }
        });

      }).catch((error) => { 
        this.logger.log(error);
        reject({resultCode: 20, resultDescription: 'USER_SRV.ERR'});
      });

    });
  }

  webGoogleLogin(): Promise<any> {
    
    return new Promise((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      this.afAuth.signInWithPopup(provider).then((data: any) => {

        if ('additionalUserInfo' in data) {
          if (data.additionalUserInfo.isNewUser) {
            let dbRef = this.afDB.database.ref(`/userData/${data.user.uid}/profile`);

            if ('given_name' in data.additionalUserInfo.profile) {
              let prof:any = data.additionalUserInfo.profile;
              dbRef.update({firstName: prof.given_name});
            }

            if ('family_name' in data.additionalUserInfo.profile) {
              let prof:any = data.additionalUserInfo.profile;
              dbRef.update({lastName: prof.family_name});
            }

            if ('picture' in data.additionalUserInfo.profile) {
              data.user.updateProfile({ photoURL: data.additionalUserInfo.profile['picture'] });
            }
          }
        }

        // This gives you a Google Access Token. You can use it to access the Google API.
        let token = data.credential.accessToken;
        // The signed-in user info.
        let user = data.user;

        resolve({resultCode: 0, resultDescription: 'USER_SRV.SUCCESS', data: data.user});

      }).catch((error) => {
        let errorCode = error.code;
        let errorMessage = error.message;
        // The email of the user's account used.
        let email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        let credential = error.credential;

        this.logger.log(error);
        reject({resultCode: 20, resultDescription: 'USER_SRV.ERR'});

      });

    });
  }

  nativeAppleLogin(): Promise<any> {
    
    return new Promise((resolve, reject) => {

      let options: SignInWithAppleOptions = {
        clientId: 'gr.qpass.userapp',
        redirectURI: environment.firebaseAppleConfiguration.redirectURI,
        scopes: 'email name',
        //state: '12345',
        //nonce: 'nonce',
      };
      
      SignInWithApple.authorize(options).then( (appleResponse: SignInWithAppleResponse) => {
        const appleAuthProvider = new firebase.auth.OAuthProvider('apple.com');

        const appleCredential = appleAuthProvider.credential({idToken: appleResponse.response.identityToken});

        this.afAuth.signInWithCredential(appleCredential).then((data) => {
          if ('additionalUserInfo' in data) {
            if (data.additionalUserInfo.isNewUser) {
              let dbRef = this.afDB.database.ref(`/userData/${data.user.uid}/profile`);
              dbRef.update({firstName: appleResponse.response.givenName});
              dbRef.update({lastName: appleResponse.response.familyName});
            }
          }
          resolve({resultCode: 0, resultDescription: 'USER_SRV.SUCCESS', data: data.user});
        }).catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          if (errorCode == 'auth/user-disabled') {
            reject({resultCode: 21, resultDescription: 'USER_SRV.ERR_USER_DISABLED_SOCIAL'});
          }
          else if (errorCode == 'auth/account-exists-with-different-credential') {
            reject({resultCode: 22, resultDescription: 'USER_SRV.ERR_EMAIL_EXISTS'});
          }
          else if (errorCode == 'auth/wrong-password') {
            reject({resultCode: 23, resultDescription: 'USER_SRV.ERR_PASS_WRONG_SOCIAL'});
          }
          else if (errorCode == 'auth/user-not-found') {
            reject({resultCode: 24, resultDescription: 'USER_SRV.ERR_USER_NOT_FOUND_SOCIAL'});
          }
          else if (errorCode == 'auth/invalid-credential') {
            reject({resultCode: 25, resultDescription: 'USER_SRV.ERR_CRED_INVALID'});
          }
          else if (errorCode == 'auth/operation-not-allowed') {
            reject({resultCode: 26, resultDescription: 'USER_SRV.ERR_OPER_NOT_ALLOWED'});
          }
          else if (errorCode == 'auth/invalid-verification-code') {
            reject({resultCode: 27, resultDescription: 'USER_SRV.ERR_VER_CODE_INVALID'});
          }
          else if (errorCode == 'auth/invalid-verification-id') {
            reject({resultCode: 28, resultDescription: 'USER_SRV.ERR_VER_ID_INVALID'});
          }
          else {
            this.logger.log(errorMessage);
            reject({resultCode: 29, resultDescription: 'USER_SRV.ERR'});
          }
        });

      }).catch((error) => { 
        this.logger.log(error);
        reject({resultCode: 30, resultDescription: 'USER_SRV.ERR'});
      });
    });
  }

  login(loginDetails):Promise<any> {
    
    let remember = loginDetails.remember_me;
    
    return new Promise(async (resolve, reject) => {
      
      try {
        datadogLogs.logger.info('User persistance requested', {user: this.currentUser, remember: remember});
        await this.afAuth.setPersistence(remember ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.NONE);
      }
      catch(err) {
        datadogLogs.logger.error('User persistance error', {user: this.currentUser, error: err});  
      }

      this.afAuth.signInWithEmailAndPassword(loginDetails.email, loginDetails.password).then((data) => {
        
        resolve({resultCode: 0, resultDescription: 'USER_SRV.SUCCESS', data: data.user});

      }).catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/user-disabled') {
          reject({resultCode: 1, resultDescription: 'USER_SRV.ERR_USER_DISABLED'});
        }
        else if (errorCode == 'auth/invalid-email') {
          reject({resultCode: 2, resultDescription: 'USER_SRV.ERR_EMAIL_INVALID'});
        }
        else if (errorCode == 'auth/wrong-password') {
          reject({resultCode: 3, resultDescription: 'USER_SRV.ERR_PASS_WRONG'});
        }
        else if (errorCode == 'auth/user-not-found') {
          reject({resultCode: 4, resultDescription: 'USER_SRV.ERR_USER_NOT_FOUND'});
        }
        else {
          reject({resultCode: 5, resultDescription: 'USER_SRV.ERR'});
          this.logger.log("Login error: (code:" + errorCode + ") " + errorMessage);
        } 
      });

    });

  }

  checkEmail(emailDetails):Promise<any> {

    return new Promise((resolve, reject) => {
      
      this.afAuth.fetchSignInMethodsForEmail(emailDetails.email).then((data: string[]) => {
        if (data.length > 0) {
          reject({resultCode: 1, resultDescription: 'USER_SRV.ERR_EMAIL_EXISTS'});
        }
        else {
          resolve({resultCode: 0, resultDescription: 'USER_SRV.SUCCESS'});
        }
      }, err => {
        reject({resultCode: 2, resultDescription: 'USER_SRV.ERR'});
      });

    });
  }

  requestReauthentication(reauthDetails):Promise<any> {
    
    return new Promise((resolve, reject) => {
      const credential = firebase.auth.EmailAuthProvider.credential(this.currentUser.email, reauthDetails.password);
      
      this.currentUser.reauthenticateWithCredential(credential).then(userCredential => {
        this.onUserChanged(userCredential.user);
        resolve({resultCode: 0, resultDescription: 'USER_SRV.AUTH_SUCCESS', data: userCredential.user});
      })
      .catch(function(error) {
        // Error occurred. Inspect error.code.
        reject({resultCode: 1, resultDescription: 'USER_SRV.ERR_AUTH'});
      });
    });
  }


  requestPasswordReset(resetDetails):Promise<any> {
    
    return new Promise((resolve, reject) => {
      
      this.afAuth.sendPasswordResetEmail(resetDetails.email).then(() => {
        resolve({resultCode: 0, resultDescription: 'USER_SRV.SUCCESS'});

      }).catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/invalid-email') {
          reject({resultCode: 1, resultDescription: 'USER_SRV.ERR_EMAIL_INVALID'});
        }
        else if (errorCode == 'auth/user-not-found') {
          reject({resultCode: 2, resultDescription: 'USER_SRV.ERR_USER_NOT_FOUND'});
        }
        else {
          this.logger.log(errorMessage);
          reject({resultCode: 3, resultDescription: 'USER_SRV.ERR'});
        } 
      });
    });
  }

  passwordReset(code: string, newPassword:string):Promise<any> {
    return new Promise((resolve, reject) => {

      this.afAuth.confirmPasswordReset(code, newPassword).then(() => {
        resolve({resultCode: 0, resultDescription: 'USER_SRV.SUCCESS'});

      }).catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/expired-action-code') {
          reject({resultCode: 1, resultDescription: 'USER_SRV.ERR_PASS_RESET_EXPIRED'});
        }
        else if (errorCode == 'auth/invalid-action-code') {
          reject({resultCode: 2, resultDescription: 'USER_SRV.ERR_PASS_RESET_INVALID'});
        }
        else if (errorCode == 'auth/user-disabled') {
          reject({resultCode: 3, resultDescription: 'USER_SRV.ERR_USER_DISABLED'});
        }
        else if (errorCode == 'auth/user-not-found') {
          reject({resultCode: 4, resultDescription: 'USER_SRV.ERR_USER_NOT_FOUND'});
        }
        else if (errorCode == 'auth/weak-password') {
          reject({resultCode: 5, resultDescription: 'USER_SRV.ERR_PASS_WEAK'});
        }
        else {
          this.logger.log(errorMessage);
          reject({resultCode: 6, resultDescription: 'USER_SRV.ERR'});
        } 
      });

    });
  }

  passwordUpdate(details):Promise<any> {
    
    return new Promise((resolve, reject) => {

      const credential = firebase.auth.EmailAuthProvider.credential(this.currentUser.email, details.old_password);
      
      this.currentUser.reauthenticateWithCredential(credential).then(user => {
        this.currentUser.updatePassword(details.password).then(user => {
          resolve({resultCode: 0, resultDescription: 'USER_SRV.SUCCESS'});
        }).catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          if (errorCode == 'auth/requires-recent-login') {
            reject({resultCode: 1, resultDescription: 'USER_SRV.ERR_REQ_RECENT_LOGIN'});
          }
          else if (errorCode == 'auth/weak-password') {
            reject({resultCode: 2, resultDescription: 'USER_SRV.ERR_PASS_WEAK'});
          }
          else {
            this.logger.log(errorMessage);
            reject({resultCode: 3, resultDescription: 'USER_SRV.ERR'});
          } 
        });
      }).catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/user-disabled') {
          reject({resultCode: 1, resultDescription: 'USER_SRV.ERR_USER_DISABLED'});
        }
        else if (errorCode == 'auth/account-exists-with-different-credential') {
          reject({resultCode: 2, resultDescription: 'USER_SRV.ERR_EMAIL_EXISTS'});
        }
        else if (errorCode == 'auth/wrong-password') {
          reject({resultCode: 3, resultDescription: 'USER_SRV.ERR_PASS_WRONG'});
        }
        else if (errorCode == 'auth/user-not-found') {
          reject({resultCode: 4, resultDescription: 'USER_SRV.ERR_USER_NOT_FOUND'});
        }
        else if (errorCode == 'auth/invalid-credential') {
          reject({resultCode: 5, resultDescription: 'USER_SRV.ERR_CRED_INVALID'});
        }
        else if (errorCode == 'auth/operation-not-allowed') {
          reject({resultCode: 6, resultDescription: 'USER_SRV.ERR_OPER_NOT_ALLOWED'});
        }
        else if (errorCode == 'auth/invalid-verification-code') {
          reject({resultCode: 7, resultDescription: 'USER_SRV.ERR_VER_CODE_INVALID'});
        }
        else if (errorCode == 'auth/invalid-verification-id') {
          reject({resultCode: 8, resultDescription: 'USER_SRV.ERR_VER_ID_INVALID'});
        }
        else {
          this.logger.log(errorMessage);
          reject({resultCode: 9, resultDescription: 'USER_SRV.ERR'});
        }
      });
    });
  }

  async logout() {
    
    let providerId: string = 'password';

    this.currentUser.providerData.forEach(function (profile) {
      providerId = profile.providerId;
    });
    
    if (Capacitor.isNativePlatform()) {
      if (providerId === firebase.auth.FacebookAuthProvider.PROVIDER_ID) {
        FacebookLogin.logout();
      }
      else if (providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID) {
        GoogleAuth.signOut();
      }
    }

    this.afAuth.signOut().then(() => {
      datadogLogs.logger.info('User logged out', {user: this.currentUser});
    });
  }

  updateProfile(profileDetails: UserProfileDetails, updatedPhotoData?: string): Promise<any> {

    return new Promise(async (resolve, reject) => {
      
      let displayName = profileDetails.firstname;
      if (profileDetails.lastname) {
        displayName += ' ' + profileDetails.lastname;
      }

      let dataObj: {displayName: string, photoURL?: string} = { displayName: displayName };
      
      try {

        if (updatedPhotoData !== this.currentUser.photoURL) {
          // user photo was updated

          if (updatedPhotoData) {
            let downloadURL: string;
            let savedPicture;
            let imagePath = '/userImages/' + this.currentUser.uid + '/profilePicture.jpg';
            
            try {
              savedPicture = await this.afStore.ref(imagePath).putString(updatedPhotoData, firebase.storage.StringFormat.DATA_URL, { contentType: 'image/jpeg' });
            }
            catch(err) {
              throw({resultCode: 1, resultDescription: 'USER_SRV.ERR_UPLOAD_IMG'});
            }
             
            try {
              downloadURL = await savedPicture.ref.getDownloadURL();
            }
            catch(err) {
              throw({resultCode: 1, resultDescription: 'USER_SRV.ERR_UPLOAD_IMG'});
            }

            dataObj.photoURL = downloadURL;

          }
          else {
            // user photo has been removed

            try {
              await this.afStore.ref('/userImages/' + this.currentUser.uid + '/profilePicture.jpg').delete();
            }
            catch(err) {
              throw({resultCode: 1, resultDescription: 'USER_SRV.ERR_UPLOAD_IMG'});
            }

            dataObj.photoURL = "";

          }

        }
      
        try {
          await this.currentUser.updateProfile(dataObj);
        }
        catch(err) {
          throw({resultCode: 3, resultDescription: 'USER_SRV.ERR_UPDATE_PROFILE'});
        }

        try {
          let usr = await this.updateUserInfo(profileDetails).toPromise();
          
          if (usr) {
            // update memory
            this._userProfile$.next({
              email: usr.email,
              firstName: usr.firstName,
              lastName: usr.lastName,
              mobile: usr.phone,
              mobileVerified: usr.verified
            });
          }
          
          resolve({resultCode: 0, resultDescription: 'USER_SRV.SUCCESS'});
        }
        catch(err) {
          throw({resultCode: 99, resultDescription: 'USER_SRV.ERR'});
        }

      }
      catch(err) {
        reject(err);
      }

    });
    
  }

  isAuthenticated(): boolean {
    return (this.currentUser && !this.currentUser.isAnonymous);
  }

  isAlternateProvider(): boolean {
    if (this.currentUser) {
      let providerId: string = 'password';

      if ('providerData' in this.currentUser) {
        this.currentUser.providerData.forEach(function (profile) {
          providerId = profile.providerId;
        });
      }

      return (providerId !== 'password');
    }

    return false;
  }

  isVerified(): boolean {
    if (this.currentUser) {
      let providerId: string = 'password';
  
      if ('providerData' in this.currentUser) {
        this.currentUser.providerData.forEach(function (profile) {
          providerId = profile.providerId;
        });
      }
      return (providerId !== 'password' || this.currentUser.emailVerified);
    }

    return false;    
  }

  getUserToken(): string | null {
    return this._userToken$.getValue();
  }

  getUserProfile(): UserProfile | null {
    return this._userProfile$.getValue();
  }

  getApplicationToken(uid: string, anonymousId: string | null = null): Observable<UserTokenResponse> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    if (anonymousId) headers = headers.append('X-Anonymous-User-Token', anonymousId);

    let body = "uid=" + uid;
    
    return this.http.post<UserTokenResponse>(environment.QpassConfiguration.apiUrl + '/user/login', body, {headers: headers});
  }

  getAnonymousUserToken(uid: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Anonymous-User-Token', uid);

    return this.http.get<AnonymousUserTokenResponse>(environment.QpassConfiguration.apiUrl + '/user/anonymous', {headers: headers}).pipe(
      map(res => res.bearer),
      catchError(this.handleError('providers.userData.getAnonymousUserToken'))
    );
  }

  getUserInfo(): Observable<UserInfo | null> {
    let token = this.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      return this.http.get<{user: UserInfo}>(environment.QpassConfiguration.apiUrl + '/user/info', {headers: headers}).pipe(
        map(res => res.user),
        catchError(this.handleError('providers.userData.getUserInfo'))
      );
    }
    else {
      return of(null);
    }
  }

  getUserHasPhoneAndIsVerified(): Observable<boolean> {
    let token = this.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      return this.http.get<UserInfoResponse>(environment.QpassConfiguration.apiUrl + '/user/info', {headers: headers}).pipe(
        map(res => res.user.phone?.length > 0 && res.user.verified),
        catchError(this.handleError('providers.userData.getUserHasPhoneAndIsVerified'))
      );
    }
    else {
      return of(false);
    }
  }

  updateUserMobileVerified(bNewVal: boolean) {
    let prof = this.getUserProfile();
    if (prof?.mobileVerified !== bNewVal ) {
      prof.mobileVerified = bNewVal;
      this._userProfile$.next(prof);
    }
  }

  sendSmsToUser(): Observable<SentSmsResponse> {
    let token = this.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      return this.http.get<SentSmsResponse>(environment.QpassConfiguration.apiUrl + '/user/verification/send', {headers: headers}).pipe(
        map(res => res),
        catchError(this.handleError('providers.userData.sendSmsToUser'))
      );
    }
    else {
      return throwError('USER_SRV.ERR');
    }
  }

  checkSmsCode(code: string): Observable<boolean> {
    let token = this.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      let url_params: HttpParams = new HttpParams();
      url_params = url_params.append('code', code);

      return this.http.get<CheckSmsResponse>(environment.QpassConfiguration.apiUrl + '/user/verification/check', {headers: headers, params: url_params}).pipe(
        map(res => res.verified),
        catchError(this.handleError('providers.userData.checkSmsCode'))
      );
    }
    else {
      return throwError('USER_SRV.ERR');
    }
  }

  updateUserInfo(profileDetails: UserProfileDetails): Observable<UserInfo> {
    let token = this.getUserToken();
    let prof = this._userProfile$.getValue();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      let url_params: HttpParams = new HttpParams();

      url_params = url_params.append('photoUrl', this.currentUser.photoURL);
      if (profileDetails.firstname) url_params = url_params.append('firstName', profileDetails.firstname);
      if (profileDetails.lastname) url_params = url_params.append('lastName', profileDetails.lastname);
      if (profileDetails.mobile) url_params = url_params.append('phone', profileDetails.mobile);
      if (profileDetails.gender) url_params = url_params.append('gender', profileDetails.gender);
      if (profileDetails.dateOfBirth) url_params = url_params.append('dateOfBirth', profileDetails.dateOfBirth);
      if (prof?.mobile != profileDetails.mobile) url_params = url_params.append('verified', "false");
      
      return this.http.get<UserInfoResponse>(environment.QpassConfiguration.apiUrl + '/user/info/set', {headers: headers, params: url_params}).pipe(
        map(res => res.user),
        catchError(this.handleError('providers.userData.updateUserInfo'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR'));
    }
  }

  updateUserMobile(newPhone: string): Observable<UserInfo> {
    let token = this.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      let url_params: HttpParams = new HttpParams();
      url_params = url_params.append('phone', newPhone);
      
      return this.http.get<UserInfoResponse>(environment.QpassConfiguration.apiUrl + '/user/info/set', {headers: headers, params: url_params}).pipe(
        map(res => res.user),
        catchError(this.handleError('providers.userData.updateUserMobile'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR'));
    }
  }

  sendEmailVerification(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.currentUser.then((u: any) => {
        u.sendEmailVerification().then(() => {
          // Verification email sent.
          resolve({resultCode: 0, resultDescription: 'USER_SRV.EMAIL_VER_SUCCESS'});
        })
        .catch(function(error: any) {
          // Error occurred. Inspect error.code.
          reject({resultCode: 98, resultDescription: 'USER_SRV.ERR_SEND_EMAIL'});
        });
      }, err => {
        reject({resultCode: 98, resultDescription: 'USER_SRV.ERR_SEND_EMAIL'});
      });
    });
  }

  async deleteTempUserData() {
    this.afDB.database.ref(`/userData/${this.currentUser.uid}`).remove();
  }

  handleUserAuthentication(val?: any, targetUrl?: string): boolean {
    if (val?.data?.actions && Array.isArray(val.data.actions)) {
      if (val.data.actions.includes("password-reset")) {
        this.translateService.get('PASSWORD_RESET.RESET_SUCCESS').subscribe(trans => {
          this.toastCtrl.create({
            message: trans,
            duration: 3000
          }).then(toastElement => {
            toastElement.present();
          });
        });
      }

      if (val.data.actions.includes("user-auth")) {
        if (targetUrl) this.navCtrl.navigateRoot(targetUrl);
        return true;
      }
      else if (val.data.actions.includes("user-guest")) {
        if (targetUrl) this.navCtrl.navigateRoot(targetUrl);
      }
    }
    else {
      if (this.currentUser) {
        if (targetUrl) this.navCtrl.navigateRoot(targetUrl);
        return true;
      }
    }
    return false;
  }

  private handleError (operation = 'operation') {
    return (err: HttpErrorResponse) => {
      let errMsg: string = "USER_SRV.ERR";
      
      if (err.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        this.logger.log(`${operation} failed: ${err.error.message}`);
        errMsg = "USER_SRV.ERR_NO_WEB_ACCESS";
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        this.logger.log(`${operation} failed: Backend returned code ${err.status}, body was: ${err.error}`);
        errMsg = "USER_SRV.ERR_SERVER_COMM";
      }
      return throwError(errMsg);
    }
  }

  private handleFacebookErrors(error, reject: (reason?: any) => void) {
    var errorCode = error.code;
    var errorMessage = error.message;
    if (errorCode == 'auth/user-disabled') {
      reject({resultCode: 21, resultDescription: 'USER_SRV.ERR_USER_DISABLED_SOCIAL'});
    } else if (errorCode == 'auth/account-exists-with-different-credential') {
      reject({resultCode: 22, resultDescription: 'USER_SRV.ERR_EMAIL_EXISTS'});
    } else if (errorCode == 'auth/wrong-password') {
      reject({resultCode: 23, resultDescription: 'USER_SRV.ERR_PASS_WRONG_SOCIAL'});
    } else if (errorCode == 'auth/user-not-found') {
      reject({resultCode: 24, resultDescription: 'USER_SRV.ERR_USER_NOT_FOUND_SOCIAL'});
    } else if (errorCode == 'auth/invalid-credential') {
      reject({resultCode: 25, resultDescription: 'USER_SRV.ERR_CRED_INVALID'});
    } else if (errorCode == 'auth/operation-not-allowed') {
      reject({resultCode: 26, resultDescription: 'USER_SRV.ERR_OPER_NOT_ALLOWED'});
    } else if (errorCode == 'auth/invalid-verification-code') {
      reject({resultCode: 27, resultDescription: 'USER_SRV.ERR_VER_CODE_INVALID'});
    } else if (errorCode == 'auth/invalid-verification-id') {
      reject({resultCode: 28, resultDescription: 'USER_SRV.ERR_VER_ID_INVALID'});
    } else {
      this.logger.log(errorMessage);
      reject({resultCode: 29, resultDescription: 'USER_SRV.ERR'});
    }
  }
}
