// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: false,
  appVersion: require('../../package.json').version + '-dev',
  logging: {
    level: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.OFF
  },
  datadogConfiguration: {
    clientToken: 'pub2ad2ba97ab3245a2fa462a050357e26e',
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    service: 'q-pass',
    env: 'dev',
    version: require('../../package.json').version,
    sampleRate: 100
  },
  googleMapsConfiguration: {
    apiKey: 'AIzaSyBx5i8LOr8w8Cxkx591-l213axspQD84dQ'
  },
  paypalConfiguration: {
    key: "AWperQLXhP-7ACoDAiMIf947GVJCWnrREEj0KKeRZDrmrYMmhRpRcZYJM1gMq86zuFsTOU-PK_R_Uzjq"
  },
  stripeConfiguration: {
    pub_key: "pk_test_51O4PrnIoC4zlPJLfMOgUDUoRQiMULNtK6llxMji5mXgTLLpRPSQkoc5Crzeu4xOL05vQvosxJa1xx1jUEF3i7FTj00JNbHKfHs",
    secret_key: "sk_test_51O4PrnIoC4zlPJLfmXwYRrtNpZFlifmjca6rzvkQqJmCyakcV9KOELKG1u2yDGd1DpHWwbvdIIBL6f7OHhwlyLjT00ASrTjLrU"
  },
  QpassConfiguration: {
    serverUrl: 'https://dev.q-pass.gr',
    apiUrl: 'https://dev.q-pass.gr/api/v1',
    termsUrl: 'https://dev.q-pass.gr/terms',
    privacyUrl: 'https://dev.q-pass.gr/privacy',
    supportUrl: 'https://dev.q-pass.gr/contact',
    androidUrl: 'https://play.google.com/store/apps/details?id=gr.qpass.userapp',
    iosUrl: 'itms-apps://itunes.apple.com/app/id1535905907',
    googleMapsApiKey: 'AIzaSyBx5i8LOr8w8Cxkx591-l213axspQD84dQ'
  },
  SMSCenterConfiguration: {
    serverUrl: 'https://smscenter.gr',
    apiUrl: 'https://q-pass.gr/api',
    key: "g5685c7d321e345"
  },
  firebaseConfiguration: {
    apiKey: "AIzaSyBx5i8LOr8w8Cxkx591-l213axspQD84dQ",
    authDomain: "q-pass.firebaseapp.com",
    databaseURL: "https://q-pass.firebaseio.com",
    projectId: "q-pass",
    storageBucket: "q-pass.appspot.com",
    messagingSenderId: "1061492826078"
  },
  firebaseAppleConfiguration: {
    redirectURI: 'https://q-pass.firebaseapp.com/__/auth/handler'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
