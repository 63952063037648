import { AfterViewInit, Component, NgZone, OnInit, Optional, ViewChild } from '@angular/core';
import { Platform, ToastController, NavController, MenuController, IonRouterOutlet } from '@ionic/angular';
import { LocaleService } from './providers/locale/locale.service';
import { UserDataService } from './providers/user-data/user-data.service';
import { BasketService } from './providers/basket/basket.service';
import { WalletService } from './providers/wallet/wallet.service';
import { OrdersService } from './providers/orders/orders.service';
import { CafesService } from './providers/cafes/cafes.service';
import { RatingsService } from './providers/ratings/ratings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from './providers/theme/theme.service';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
  RegistrationError,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { NGXLogger } from 'ngx-logger';
import { registerLocaleData } from '@angular/common';
import localeEl from '@angular/common/locales/el'
import localeEnEgb from '@angular/common/locales/en-GB'
import {PersonalDataSettingsService} from "./providers/personal-data-settings.service";
import { datadogLogs } from '@datadog/browser-logs';
import { environment } from 'src/environments/environment';
import { Plugins } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { CommunitiesService } from './providers/communities/communities.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { QuserLoadingOverlayComponent, QLoadingOverlayService } from '@q-pass/user';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild('routerOutlet', { static : true }) routerOutlet: IonRouterOutlet;
  @ViewChild(QuserLoadingOverlayComponent) loadingModal!: QuserLoadingOverlayComponent;

  dismissing: boolean;
  lastBack: number;
  spamming: boolean;
  _CLOSE_DELAY = 2000;
  _SPAM_DELAY = 500;

  constructor(
    private platform: Platform,
    private router: Router,
    private zone: NgZone,
    private navCtrl: NavController,
    private menu: MenuController,
    private toastCtrl: ToastController,
    private localeService: LocaleService,
    private themeService: ThemeService,
    private userService: UserDataService,
    private personalDataSettingsService: PersonalDataSettingsService,
    private basketService: BasketService,
    private walletService: WalletService,
    private ordersService: OrdersService,
    private cafesService: CafesService,
    private communitiesService: CommunitiesService, 
    private ratingsService: RatingsService,
    private logger: NGXLogger,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    public loadingService: QLoadingOverlayService
    // @Optional() private routerOutlet?: IonRouterOutlet
  ) {
    this.initializeApp();
  }

  initializeApp() {

    datadogLogs.init(environment.datadogConfiguration);

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = "app.q-pass.gr";
        const pathArray = event.url.split(domain);
        // pathArray is like ['https://q-pass.gr', '/cafeShop/2']
        
        // Get the last element with pop()
        const appPath = pathArray.pop();
        if (appPath) {
          this.navCtrl.navigateRoot(appPath);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });

    this.platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.

      this.localeService.initLocale();

      GoogleAuth.initialize();

      if (Capacitor.isNativePlatform()) {
        // make your native calls
        this.pushInit();
      }

      this.backbuttonSubscribeMethod();

    });
  }

  ngOnInit() {
    registerLocaleData(localeEl);
    registerLocaleData(localeEnEgb);
  }

  ngAfterViewInit() {
    this.loadingService.setLoadingModal(this.loadingModal);
  }

  pushInit() {

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then( result => {
      this.localeService.pushNotificationsPermissionGranted(result.receive === 'granted');
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {

        this.logger.debug('Device registered, token ' + token.value);
        
        this.localeService.updatePushToken(token.value);
        this.localeService.addDevice().then(params => {
          this.logger.debug(params);
        }, err => {
          this.logger.error(err);
        });

      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: RegistrationError) => {
        this.logger.error('Error on Push registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        this.logger.debug('Received a notification');
        this.logger.debug(JSON.stringify(notification));
        this.handlePushNotification(notification);
      }
    );

    PushNotifications.addListener('pushNotificationActionPerformed',
      (notificationAction: ActionPerformed) => {
        this.logger.debug('Push action performed.');
        this.logger.debug(JSON.stringify(notificationAction));
        this.handlePushNotification(notificationAction.notification);
      }
    );
  }

  handlePushNotification(notification: PushNotificationSchema) {
    if(notification.data.type === 'community_status_change') {
      this.handleCommunityStatusChangeNotification(notification);
    }
    if ('type' in notification.data && 'params' in notification.data) {
      this.handleOrderNotification(notification);
    }
  }

  private handleCommunityStatusChangeNotification(notification: PushNotificationSchema) {
    if(notification.data.communityId && notification.data.communityId) {
      this.navigateToCommunityPage(notification.data.communityId, notification.data.membershipId);
    } else {
      this.logger.debug('Incorrect push message format (no communityId or membershipId field)');
      return;
    }
  }

  private navigateToCommunityPage(communityId: number, membershipId: number) {
    this.navCtrl.setDirection('forward');
    this.router.navigateByUrl('communities/community-info/' + communityId + "/" + membershipId + "/status");
  }

  private handleOrderNotification(notification: PushNotificationSchema) {
    let obj: any = {};
    let params: { orderId: number, status?: string, uat?: number, delay?: number, eta?: number } = {orderId: 0};

    if (typeof notification.data.params === "object") {
      if ('orderId' in notification.data.params) {
        params = notification.data.params;
      } else {
        this.logger.debug('Incorrect push message format (no params field)');
        return;
      }
    } else {
      try {
        params = JSON.parse(notification.data.params);
      } catch (e) {
        this.logger.warn(`Error while parsing params of push message: ${e}`);
        return;
      }
    }

    if (notification.data.type === 'order_update') {
      obj.id = params.orderId;
      obj.status = params.status;
      obj.uat = params.uat;
      if (params.delay) obj.delay = params.delay;

      this.ordersService.updateActiveOrder(obj);
    } else if (notification.data.type === 'eta_update') {
      obj.id = params.orderId;
      obj.eta = params.eta;
      this.ordersService.updateActiveOrder(obj);
    }
  }

  backbuttonSubscribeMethod() {
    this.platform.backButton.subscribeWithPriority(-1, () => {
      if (this.routerOutlet && !this.routerOutlet.canGoBack()) {
        if (this.router.url === '/home') {
          if (((Date.now() - this.lastBack) < this._CLOSE_DELAY) &&
            (Date.now() - this.lastBack) > this._SPAM_DELAY) {
            // Leaves app if user pressed button not faster than 500ms a time,
            // and not slower than 2000ms a time.
            App.exitApp();
          } else {
            if (!this.spamming) { // avoids multiple toasts caused by button spam.
              const t = this.toastCtrl.create({
                message: this.translateService.instant('APP.EXIT_MSG'),
                duration: this._CLOSE_DELAY,
                cssClass: 'toast-center'
              }).then((toastData) => {
                toastData.present();
                toastData.onDidDismiss().then(() => {
                  this.spamming = false;
                });
              });
            }
            this.spamming = true;
          }

          this.lastBack = Date.now();
        } else {
          if (this.router.url !== '/splash') {
            this.navCtrl.navigateRoot('home');
          }
        }
      }
    });
  }

}
