
export interface SectorDto {
  id: number;
  shop_id: number;
  type: SectorType;
  name: string;
  description: string;
  spots: SpotDto[];
}

export enum SectorType {
  normal = 'normal',
  parking = 'parking',
  table_group = 'table_group',
  q_table = 'qtable'
}

export interface SpotDto {
  id: number;
  name: string;
}

