import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-rejected-orders-modal',
  templateUrl: './rejected-orders-modal.component.html',
  styleUrls: ['./rejected-orders-modal.component.scss']
})
export class RejectedOrdersModalComponent implements OnInit {

  @Input() orderInfo: any;
  @Input() showGoToOrder: boolean;

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit(): void {
  }

  goToOrder() {
    this.modalCtrl.dismiss({
      orderId: this.orderInfo.id
    });
  }

  dismiss() {
    this.modalCtrl.dismiss({});
  }

}
