<ion-content fullscreen="true">

  <div id="bg-map">
    <img src="assets/images/splash/spl_map.svg"/>
  </div>

  <div id="spot-shadow">
    <img src="assets/images/splash/spl_shadow.svg"/>
  </div>

  <div id="qpass-logo-container">
      
    <div id="q-icon">
      <img src="assets/images/splash/spl_Q-icon.svg"/>
    </div>

    <div id="q-container">
      <div id="Q-letter">
        <img src="assets/images/splash/spl_Q.svg"/>
      </div>
      <div id="image1" class="image">
        <img src="assets/images/splash/spl_image1.png"/>
      </div>
      <div id="image2" class="image">
        <img src="assets/images/splash/spl_image2.png"/>
      </div>
      <div id="image3" class="image">
        <img src="assets/images/splash/spl_image3.png"/>
      </div>
      <div id="image4" class="image">
        <img src="assets/images/splash/spl_image4.png"/>
      </div>
      <div id="image5" class="image">
        <img src="assets/images/splash/spl_image5.png"/>
      </div>
    </div>

    <div id="qpass-text">
      <img src="assets/images/splash/spl-Pass.svg"/>
    </div>

    <div id="qpass-moto">
      Explore like a local
    </div>

  </div>
</ion-content>

<ion-footer *ngIf="(splashTimedOut$ | async) && !(checksResolved$ | async)">
  <ion-toolbar>
    <ion-grid>
      <ion-row style="height: 64px">
        <ion-col class="ion-text-center">

          <div>
            <div *ngIf="!(updateCheckError$ | async)">
              <ion-spinner *ngIf="splashTimedOut$ | async"></ion-spinner>
            </div>
            <quser-button *ngIf="updateCheckError$ | async" [label]="'SPLASH.CONN_ERROR' | translate" 
              [width]="'100%'" [dark]="true" (onClick)="onRetryChecks()"></quser-button>

          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>