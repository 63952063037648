import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { UserDataService } from '../user-data/user-data.service';
import { ShopItem } from "../../models/shop-item.interface";
import { Preferences } from '@capacitor/preferences';

interface Memberships {
  memberships: Membership[];
}

export interface Membership {
  id: number;
  accepted: number;
  rejected: number;
  community: Community
}

export interface Communities {
  communities: Community[];
}

export interface CommunityInfo {
  community: Community;
}

export interface MembershipInfo {
  membership: Membership;
}

export interface Community {
  id: number;
  name: string;
  description: string
  type: string;
  accessType: string
  discoveryMethod: string;
  shops: ShopItem[];
}

@Injectable({
  providedIn: 'root'
})
export class CommunitiesService {

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    public userDataService: UserDataService,
  ) {

    this.userDataService.userToken$.subscribe(token => {
      
      if (token) {
        Preferences.get({ key: 'QR_communityId' }).then((data) => {
          const communityId = data.value;
          if (communityId) {
            this.joinCommunityById(+communityId).subscribe(result => {
              if (+communityId == result.community.id) {
                Preferences.remove({ key: 'QR_communityId' });
              }
            });
          }
        });
      }

    });
    
  }

  getMemberships(): Observable<Membership[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.userDataService.getUserToken());
    return this.http.get<Memberships>(environment.QpassConfiguration.apiUrl + '/community/list', {headers: headers}).pipe(
      map(res => res.memberships),
      catchError(this.handleError('providers.getMemberships'))
    );
  }

  getCommunities(searchText: string): Observable<Community[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.userDataService.getUserToken());
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('query', searchText);
    return this.http.get<Communities>(environment.QpassConfiguration.apiUrl + '/community/find', {headers: headers, params: url_params}).pipe(
        map(res => res.communities),
        catchError(this.handleError('providers.getCommunities'))
    );
  }

  getCommunityById(communityId: number): Observable<Community> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.userDataService.getUserToken());
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('id', communityId);
    return this.http.get<CommunityInfo>(environment.QpassConfiguration.apiUrl + '/community/info', {headers: headers, params: url_params}).pipe(
        map(res => res.community),
        catchError(this.handleError('providers.getCommunityById'))
    );
  }

  getMembershipById(membershipId: number): Observable<Membership> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.userDataService.getUserToken());
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('id', membershipId);
    return this.http.get<MembershipInfo>(environment.QpassConfiguration.apiUrl + '/community/application', {headers: headers, params: url_params}).pipe(
        map(res => res.membership),
        catchError(this.handleError('providers.getMembershipById'))
    );
  }

  joinCommunityById(communityId: number): Observable<Membership> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.userDataService.getUserToken());
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('id', communityId);
    return this.http.get<MembershipInfo>(environment.QpassConfiguration.apiUrl + '/community/join', {headers: headers, params: url_params}).pipe(
        map(res => res.membership),
        catchError(this.handleError('providers.getMembershipById'))
    );
  }

  joinCommunityByIdWithAccessCode(communityId: number, accessCode: string): Observable<Membership> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.userDataService.getUserToken());
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('id', communityId);
    url_params = url_params.append('code', accessCode);
    return this.http.get<MembershipInfo>(environment.QpassConfiguration.apiUrl + '/community/join', {headers: headers, params: url_params}).pipe(
        map(res => res.membership),
        catchError(this.handleError('providers.getMembershipById'))
    );
  }

  getPendingApplications(): Observable<Membership[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.userDataService.getUserToken());
    return this.http.get<Memberships>(environment.QpassConfiguration.apiUrl + '/community/applications', {headers: headers}).pipe(
        map(res => res.memberships),
        catchError(this.handleError('providers.getMembershipById'))
    );
  }

  leaveCommunityById(communityId: number): Observable<Membership> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.userDataService.getUserToken());
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('id', communityId);
    return this.http.get<MembershipInfo>(environment.QpassConfiguration.apiUrl + '/community/leave', {headers: headers, params: url_params}).pipe(
        map(res => res.membership),
        catchError(this.handleError('providers.getMembershipById'))
    );
  }

  private handleError (operation = 'operation') {
    return (err: HttpErrorResponse) => {
      let errMsg: string = "USER_SRV.ERR";

      if (err.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        this.logger.log(`${operation} failed: ${err.error.message}`);
        errMsg = "USER_SRV.ERR_NO_WEB_ACCESS";
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        this.logger.log(`${operation} failed: Backend returned code ${err.status}, body was: ${err.error}`);
        errMsg = "USER_SRV.ERR_SERVER_COMM";
      }
      return throwError(errMsg);
    }
  }

}
