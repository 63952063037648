import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, catchError, pluck, filter, take, mergeMap } from 'rxjs/operators';
import { LocaleService } from '../locale/locale.service';
import { Observable, from, of, throwError } from 'rxjs';
import { MenuCategory } from 'src/app/models/menu-category.interface';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { UserDataService } from '../user-data/user-data.service';
import { ShopPromos } from 'src/app/models/shop-promos.interface';
import { WorkingHourRange } from 'src/app/models/shop-item.interface';
import { ProductDetails } from 'src/app/models/product-details.interface';
import { NgHttpCachingHeaders, NgHttpCachingService } from 'ng-http-caching';
import { Preferences } from '@capacitor/preferences';

interface MenuCategoriesResponse {
  categories: any[];
}

interface CategoryProductsResponse {
  products: any[];
}

interface ProductDetailsResponse {
  product: ProductDetails;
}

interface ProductOptionsResponse {
  groups: any[];
}

interface ShopOffersResponse {
  offers: Array<any>;
  coupons: Array<any>;
  jointPromotions: Array<any>;
}

interface ShopOffersAndCouponsResponse {
  offers: Array<any>;
  coupons: Array<any>;
}

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private http: HttpClient,
    private localeService: LocaleService,
    private userDataService: UserDataService,
    private ngHttpCachingService: NgHttpCachingService,
    private logger: NGXLogger
  ) {
    
  }

  isImageUrl(url: string): boolean {
    const imageFormats: string[] = [".jpg", ".jpeg", ".png", ".gif", ".svg"]; // List of supported image formats
    for (const format of imageFormats) {
      if (url?.toLowerCase().endsWith(format)) {
        return true;
      }
    }
    return false;
  }

  getMenuCategories(cafeID, checkout = 0, withProducts: boolean = false): Observable<MenuCategory[]> {
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('shopId', cafeID);
    url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));
    if (checkout == 1) url_params = url_params.append('type', 'checkout');
    if (withProducts) url_params = url_params.append('withProducts', 'true');

    return this.http.get<MenuCategoriesResponse>(environment.QpassConfiguration.apiUrl + '/categories', {params: url_params}).pipe(
      map(res => res.categories),
      catchError(this.handleError('providers.products.getMenuCategories'))
    );
  }
  
  /* The changes for when we apply the new API and replace the above function, at the moment is a bit buggy
  getMenuCategories(cafeID, checkout = 0, withProducts: boolean = false): Observable<any[]> {
    //  let url_params: HttpParams = new HttpParams();
    //  url_params = url_params.append('shopId', cafeID);
    //  url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));
      // if (checkout == 1) url_params = url_params.append('type', 'checkout');
      // if (withProducts) url_params = url_params.append('withProducts', 'true');
      let headers = new HttpHeaders();
      headers = headers.append('X-App-Locale',this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()).toLowerCase().substring(0,2));
       
      return this.http.get<GetShopCategoriesResponse>(environment.QpassConfiguration.apiUrl + "/shops/" + cafeID + "/categories?sortOrder=DESC&sortBy=id&" + this.ALL_PAGES_PARAM, { headers }).pipe(  
        // this.http.get<MenuCategoriesResponse>(environment.QpassConfiguration.apiUrl + '/categories', {params: url_params}).pipe(
        map(res => res.records),
        catchError(this.handleError('providers.products.getMenuCategories'))
      );
    }
    */
  
  getCategoryProducts(categoryID): Observable<any> {
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('categoryId', categoryID);
    url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));
    
    return this.http.get<CategoryProductsResponse>(environment.QpassConfiguration.apiUrl + '/products', {params: url_params}).pipe(
      map(res => res.products),
      catchError(this.handleError('providers.products.getCategoryProducts'))
    );
  }

  getProductDetails(productID, full=false): Observable<ProductDetails> {
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('productId', productID);
    url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));
    
    if(full) {
      url_params = url_params.append('full', 'true');
    }

    return this.http.get<ProductDetailsResponse>(environment.QpassConfiguration.apiUrl + '/products/details', {params: url_params}).pipe(
      map(res => res.product),
      catchError(this.handleError('providers.products.getProductDetails'))
    );
  }

  getProductOptions(productID): Observable<any> {
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('productId', productID);
    url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));

    return this.http.get<ProductOptionsResponse>(environment.QpassConfiguration.apiUrl + '/options', {params: url_params}).pipe(
      map(res => res.groups),
      catchError(this.handleError('providers.products.getProductOptions'))
    );
  }

  getShopPromos(shopId, forceUpdate: boolean = false): Observable<ShopPromos> {
    if (forceUpdate) {
      this.ngHttpCachingService.clearCacheByTag('SHOP_PROMOS');
    }

    const userToken = this.userDataService.getUserToken();
    
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('shopId', shopId);
    url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));

    let headers = new HttpHeaders();
    headers = headers.append(NgHttpCachingHeaders.ALLOW_CACHE, '1');
    headers = headers.append(NgHttpCachingHeaders.TAG, 'SHOP_PROMOS');
    if (userToken?.length > 0) {      
      headers = headers.append('Authorization', 'Bearer ' + userToken);
    }
    
    return from(Preferences.get({ key: 'QR_communityId' })).pipe(mergeMap((res) => {   
      if (res.value) {
        url_params = url_params.append('community', res.value);
      }
      return this.http.get<ShopPromos>(environment.QpassConfiguration.apiUrl + '/shop/promo', {headers, params: url_params});
    })).pipe(
      map(res => res),
      catchError(this.handleError('providers.products.getShopPromos'))
    );
    
    // return this.http.get<ShopPromos>(environment.QpassConfiguration.apiUrl + '/shop/promo', {headers, params: url_params}).pipe(
    //   map(res => res),
    //   catchError(this.handleError('providers.products.getShopPromos'))
    // );
  }

  private handleError (operation = 'operation') {
    return (err: HttpErrorResponse) => {
      let errMsg: string = "USER_SRV.ERR";
      
      if (err.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        this.logger.log(`${operation} failed: ${err.error.message}`);
        errMsg = "USER_SRV.ERR_NO_WEB_ACCESS";
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        this.logger.log(`${operation} failed: Backend returned code ${err.status}, body was: ${err.error}`);
        errMsg = "USER_SRV.ERR_SERVER_COMM";
      }
      return throwError(errMsg);
    }
  }

}
