import { Observable, BehaviorSubject, throwError, of, observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, pluck, filter, take, tap } from 'rxjs/operators';
import { Transaction } from '../../models/transaction.interface';
import { Events } from '../events/events.service';
import { environment } from 'src/environments/environment';
import { PaymentMethod } from 'src/app/constants/common.constants';
import { StoredCard } from '@q-pass/user';
import { NGXLogger } from 'ngx-logger';
import { UserDataService } from '../user-data/user-data.service';
import { datadogLogs } from '@datadog/browser-logs';

interface WalletInfoResponse {
  user: any;
}

interface TransactionsResponse {
  payments: Transaction[];
}

interface CreatePaymentResponse {
  payment: Transaction;
  balance: number;
}

interface StoredCardsResponse {
  cards: Array<StoredCard>;
}

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  private _wallet: BehaviorSubject<number> = new BehaviorSubject(0);
  public readonly wallet: Observable<number> = this._wallet.asObservable();
  
  constructor(
    private http: HttpClient,
    private events: Events,
    private userDataService: UserDataService,
    private logger: NGXLogger
  ) {

  }

  getWalletInfo() {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      this.http.get<WalletInfoResponse>(environment.QpassConfiguration.apiUrl + '/user/info', {headers: headers}).pipe(
        map(res => res.user)
      ).subscribe(data => {
        this._wallet.next(data.balance);
      }, err => {
        console.error(err);
      });
    }
  }

  getTransactions(pageIndex: number = -1, pageCount?: number, filterType?: string, filterDate?: any): Observable<Array<Transaction>> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      let url_params: HttpParams = new HttpParams();
      if (pageIndex >= 0) url_params = url_params.append('page', pageIndex.toString());
      if (pageCount > 0) url_params = url_params.append('count', pageCount.toString());
      if (filterType && filterType!== 'all') url_params = url_params.append('type', filterType);
      if (filterDate && filterDate.enabled) {
        if (filterDate.duration.from > 0) url_params = url_params.append('dateFrom', filterDate.duration.from);
        if (filterDate.duration.to >= filterDate.duration.from) url_params = url_params.append('dateTo', filterDate.duration.to);
      }
      return this.http.get<TransactionsResponse>(environment.QpassConfiguration.apiUrl + '/user/payment/list', {headers: headers, params: url_params}).pipe(
        map(res => res.payments),
        catchError((err: HttpErrorResponse) => this.handleError(err, 'providers.wallet.getTransactions'))
      );
    }
    else {
      of([]);
    }
  }

  getAvailablePaymentMethods(): Array<StoredCard> {

    return [];
  }

  getStoredCardsList(): Observable<Array<StoredCard>> {
    return this.http.get<any[]>(environment.QpassConfiguration.apiUrl + '/payment-methods').pipe(
      map(res => res.map((item: any) => { return {id: item.id, name: item.metadata?.friendlyName || '', expDate: {month: item.card?.exp_month || 0, year: item.card?.exp_year || 0}, lastDigits: item.card?.last4 || '', cardType: item.card?.brand || '', cardFunding: item.card?.funding || '', cardDisplayBrand: item.card?.display_brand || ''}; } )),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) {
          return of([]);
        }
        else this.handleError(err, 'providers.wallet.getStoredCardsList');
      })
    );
  }

  createStripeSetupIntent(): Observable<string> {
    return this.http.post<{clientSecret: string}>(environment.QpassConfiguration.apiUrl + '/setup-intend', {}).pipe(
      map(res => res.clientSecret),
      catchError((err: HttpErrorResponse) => this.handleError(err, 'providers.wallet.createStripeSetupIntent'))
    );
  }

  createStripePaymentIntent(amount: number, paymentId: string, saveCard: boolean = false): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    let body = {
      amount: Math.round(amount * 100),
      transactionToken: paymentId,
      currency: 'eur',
      saveCard: saveCard
    };
    return this.http.post<any>(environment.QpassConfiguration.apiUrl + '/payment-intend', body, {headers: headers}).pipe(
      map(res => 
        {
          return res.clientSecret
        }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 409) {
          return throwError(() => new Error('WALLET.AMOUNTS_NOT_MATCHING'));
        }
        return this.handleError(err, 'providers.wallet.createStripeSetupIntent');
      })
    );
  }

  getStripePaymentIntent(paymentIntentId: string): Observable<any> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);
      
      return this.http.get(environment.QpassConfiguration.apiUrl + '/payment-intend/' + paymentIntentId, {headers: headers}).pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, 'providers.wallet.getStripePaymentIntent'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR_NO_TOKEN'));
    }
  }

  updateStoredCard(updatedCardId: string, expDate: {month: number, year: number} | null, name: string) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    
    let body = {};

    if (expDate) {
      body = {...body, cardExpMonth: expDate.month, cardExpYear: expDate.year};
    }
    if (name) {
      body = {...body, friendlyName: name};
    }

    return this.http.put(environment.QpassConfiguration.apiUrl + '/payment-methods/' + updatedCardId, body, {headers: headers}).pipe(
      //map(res => res.card),
      catchError((err: HttpErrorResponse) => this.handleError(err, 'providers.wallet.updateStoredCard'))
    );
    
  }

  removeStoredCard(cardId): Observable<boolean> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      return this.http.delete(environment.QpassConfiguration.apiUrl + '/payment-methods/' + cardId, {headers: headers}).pipe(
        map(res => true),
        catchError((err: HttpErrorResponse) => this.handleError(err, 'providers.wallet.removeStoredCard'))
      );
    }
    else {
      return throwError('USER_SRV.ERR_NO_TOKEN');
    }
  }

  depositMoney(amount, method: PaymentMethod, paymentId, trnsRef?, orderId?, bookingId?): Promise<any> {
    return new Promise((resolve, reject) => {
      
      let token = this.userDataService.getUserToken();
    if (token) {

        let headers = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer ' + token);

        let url_params: HttpParams = new HttpParams();
        url_params = url_params.append('amount', amount);
        url_params = url_params.append('method', method);
        url_params = url_params.append('type', 'deposit');
        
        if (method == PaymentMethod.paypal) {
          url_params = url_params.append('paypal_order_id', paymentId);
        }
        else if (method == PaymentMethod.creditCard) {
          url_params = url_params.append('order_id', paymentId);
          if (trnsRef) {
            url_params = url_params.append('transaction_ref', trnsRef);
          }
        }

        if (orderId) {
          url_params = url_params.append('orderId', orderId);
        }
        else {
          if (bookingId) {
            url_params = url_params.append('bookingId', bookingId);
          }
        }
        
        datadogLogs.logger.info('Money deposit requested', {url: environment.QpassConfiguration.apiUrl + '/user/payment/new', headers: headers, params: url_params});
        this.http.get<CreatePaymentResponse>(environment.QpassConfiguration.apiUrl + '/user/payment/new', {headers: headers, params: url_params}).subscribe(res => {
          datadogLogs.logger.info('Money deposit success', {result: res});
          this._wallet.next(res.balance);
          resolve({resultCode: 0, resultDescription: 'MONEY_DEPOSIT_SRV.SUCCESS'});
        }, err => {
          datadogLogs.logger.error('Money deposit failed', {result: err});
          reject({resultCode: 1, resultDescription: 'MONEY_DEPOSIT_SRV.ERR'});
        });
      }
      else {
        datadogLogs.logger.error('Money deposit called without user token', {});
        reject({resultCode: 1, resultDescription: 'USER_SRV.ERR_NO_TOKEN'});
      }
      
    });

  }

  getWallet() {
    return this._wallet.getValue();
  }

  getCreditCardIcon(card: StoredCard): string {
    let valBrand = card.cardType.toLowerCase();
    let valFunding = card.cardFunding.toLowerCase();
    let valDisplayBrand = card.cardDisplayBrand.toLowerCase();

    let img: string = 'assets/images/creditcard.png';
    if (valDisplayBrand === 'amex') {
      img = 'assets/images/creditCards/american-express.svg';
    }
    else if (valDisplayBrand === 'discover') {
      img = 'assets/images/creditCards/discover.svg';
    }
    else if (valDisplayBrand === 'diners_club') {
      img = 'assets/images/creditCards/diners.svg';
    }
    else if (valDisplayBrand === 'jcb') {
      img = 'assets/images/creditCards/jcb.svg';
    }
    else if (valDisplayBrand === 'union_pay') {
      img = 'assets/images/creditCards/unionpay.svg';
    }
    else if (valDisplayBrand === 'cartes_bancaires') {
      img = 'assets/images/creditCards/cartes_bancaires.svg';
    }
    else if (valDisplayBrand === 'eftpos_australia') {
      img = 'assets/images/creditCards/eftpos.svg';
    } // Visa and Mastercard are checked last as more generic 
    else if (valBrand === 'visa') {
      if (valFunding === 'credit') {
        img = 'assets/images/creditCards/visa.svg';
      }
      else if (valFunding === 'debit') {
        img = 'assets/images/creditCards/visa_debit.svg';
      }
      else {
        img = 'assets/images/creditCards/visa.svg';
      }
    }
    else if (valBrand === 'mastercard') {
      if (valFunding === 'credit') {
        img = 'assets/images/creditCards/mastercard.svg';
      }
      else if (valFunding === 'debit') {
        img = 'assets/images/creditCards/mastercard_debit.svg';
      }
      else {
        img = 'assets/images/creditCards/mastercard.svg';
      }
    }
     
    return img;
  }

  private async getHeaders(): Promise<HttpHeaders> {
    const token = this.userDataService.getUserToken();

    return new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
  }

  private handleError (err: HttpErrorResponse, operation = 'operation') {
    let errMsg: string = "USER_SRV.ERR";
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      this.logger.log(`${operation} failed: ${err.error.message}`);
      errMsg = "USER_SRV.ERR_NO_WEB_ACCESS";
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      this.logger.log(`${operation} failed: Backend returned code ${err.status}, body was: ${err.error}`);
      errMsg = "USER_SRV.ERR_SERVER_COMM";
    }
    return throwError(() => new Error(errMsg));
  }


}
