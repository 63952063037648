import { Observable, BehaviorSubject, forkJoin, combineLatest, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ProductsService } from '../products/products.service';
import { NGXLogger } from 'ngx-logger';
import { BasketContents } from 'src/app/models/basket-contents.interface';
import { finalize, map } from 'rxjs/operators';
import { OrderSpeedRest } from 'src/app/models/order-speed-rest.interface';
import { BasketProduct } from 'src/app/models/basket-product.interface';
import { BasketGroup } from 'src/app/models/basket-group.interface';
import { BasketOption } from 'src/app/models/basket-option.interface';
import { BasketRest } from 'src/app/models/basket-rest.interface';
import { ActivePromo, Promo } from 'src/app/models/order-promo.interface';
import { Order } from 'src/app/models/order.interface';
import { Product, ProductOptions } from 'src/app/models/product.interface';
import { OrdersService } from '../orders/orders.service';
import { HttpRequestState } from 'src/app/models/http-request-state.interface';
import { ProductDetails } from 'src/app/models/product-details.interface';
import { ProductRest } from 'src/app/models/product-rest.interface';
import { OrderPaymentAmounts } from 'src/app/models/order-payment.interface';
import { computeDiscountedPrice } from "../../models/utility-functions";
import { CafesService } from "../cafes/cafes.service";


@Injectable({
  providedIn: 'root'
})
export class BasketService {

  private _basketContents: BehaviorSubject<BasketContents> = new BehaviorSubject(null);
  public readonly basketContents: Observable<BasketContents> = this._basketContents.asObservable();
  public freeItems$: BehaviorSubject<Array<BasketProduct>> = new BehaviorSubject([]);
  public orderContent$: BehaviorSubject<Order> = new BehaviorSubject(null);
  public orderPaymentAmounts$: BehaviorSubject<OrderPaymentAmounts> = new BehaviorSubject({analysis: {itemsTotal: 0, checkoutItemsTotal: 0, delivery: 0, discount: 0}});
  public activePromo$: BehaviorSubject<ActivePromo> = new BehaviorSubject(null);
  public activePromoDetails$: BehaviorSubject<HttpRequestState<Promo>> = new BehaviorSubject(null);
  public guestBasket$: BehaviorSubject<BasketRest> = new BehaviorSubject({shop_id: 0, items: []});

  public basketTotalItemsNumber: Observable<number>;
  public basketCheckoutItemsNumber: Observable<number>;
  public orderTotalCost: Observable<number>;
  public orderTotalCostWithoutDiscount: Observable<number>;
  public userBasketRef: any;
  public basketLoading: boolean = false;
  public basketErrorMessage: string = '';

  public activePromoAllShops$: BehaviorSubject<ActivePromo[]> = new BehaviorSubject([]);
  
  constructor(
    public productsService: ProductsService, 
    private ordersService: OrdersService,
    private afAuth: AngularFireAuth,
    private afDB: AngularFireDatabase,
    private logger: NGXLogger,
    private cafesService: CafesService
  ) {
    
    this.basketTotalItemsNumber = this.basketContents.pipe(
      map(res => {
        let cnt = 0;
        res?.items.map(itm => cnt+= itm.quantity);
        res?.checkout?.filter(chkOutItm => chkOutItm.quantity > 0).map(itm => cnt+= itm.quantity);
        return cnt;
      })
      // map(res => res.items.length + (res.checkout ? res.checkout.filter(chkOutItm => chkOutItm.quantity > 0).length : 0))
    );

    this.basketCheckoutItemsNumber = this.basketContents.pipe(
      map(res => {
        let cnt = 0;
        res?.checkout?.filter(chkOutItm => chkOutItm.quantity > 0).map(itm => cnt+= itm.quantity);
        return cnt;
      })
    );
    this.computeOrderTotalCost();
    this.computeOrderTotalCostWithoutDiscount();

    this.basketContents.subscribe(bsk => {
      if (bsk === null) return;
      this.storeBasket(bsk);
      if (bsk.shop_id > 0) {
        let orderContent: Order = this.orderContent$.getValue();
        if (!orderContent) {
          orderContent = {
            shopId: bsk.shop_id,
            dat: 0,
            type: "",
            payment: {
              amount: 0,
              method: ""
            },
            products: [],
          }
        }

        orderContent.products = this.convertBasketContentToOrderProducts(bsk);
        orderContent.payment.amount = parseFloat(bsk.totalCost.toFixed(2));
        this.orderContent$.next(orderContent);
        
        let orderPaymentAmounts = this.orderPaymentAmounts$.getValue();
        orderPaymentAmounts.analysis.itemsTotal = orderPaymentAmounts.analysis.itemsTotal = 0;
        bsk.items.forEach((itm, idx) => {
          orderPaymentAmounts.analysis.itemsTotal += itm.totalCost || 0;
        });
        orderPaymentAmounts.analysis.checkoutItemsTotal = bsk.totalCost - orderPaymentAmounts.analysis.itemsTotal;
        this.orderPaymentAmounts$.next(orderPaymentAmounts);
      }
      else {
        this.orderContent$.next(null);
        this.orderPaymentAmounts$.next({analysis: {itemsTotal: 0, checkoutItemsTotal: 0, delivery: 0, discount: 0}});
      }

      this.orderContent$.subscribe(orderContent => {
        let orderPaymentAmounts = this.orderPaymentAmounts$.getValue();
        if (orderContent?.payment?.analysis?.delivery) {
          if (orderPaymentAmounts.analysis.delivery !== orderContent.payment.analysis.delivery) {
            orderPaymentAmounts.analysis.delivery = orderContent.payment.analysis.delivery;
            this.orderPaymentAmounts$.next(orderPaymentAmounts);
          }
        }
        else {
          if (orderPaymentAmounts.analysis.delivery > 0) {
            orderPaymentAmounts.analysis.delivery = 0;
            this.orderPaymentAmounts$.next(orderPaymentAmounts);
          }
        }
      });

      // this.activePromo$.subscribe(activePromo => {
      //   let orderPaymentAmounts = this.orderPaymentAmounts$.getValue();
      //   if (!activePromo) {
      //     if (orderPaymentAmounts.analysis?.discount) {
      //       delete orderPaymentAmounts.analysis.discount;
      //       this.orderPaymentAmounts$.next(orderPaymentAmounts);
      //     }
      //   }
      // });
    });

    const unsubscribe = this.afAuth.authState.subscribe((user) => {
      if(user) { 
        this.userBasketRef = this.afDB.database.ref(`/userData/${user.uid}/basket`);
        this.populateBasket();
      }
      else {
        this.userBasketRef = null;
        this.setActivePromo(null, 0);
        this._basketContents.next({shop_id: 0, items: [], totalCost: 0});
      }
      
    });
  }

  private computeOrderTotalCost() {
    this.orderTotalCost = this.orderPaymentAmounts$.pipe(map(res => {
      let total: number = 0
      total = res.analysis.itemsTotal + (res.analysis.checkoutItemsTotal || 0) + (res.analysis.delivery || 0) - (res.analysis.discount || 0);
      if(total<=0)
        total=0;
      else{
        total=Math.round(total* 100);                 // Round to 2 decimal places (since it's the order's total cost)
        total= Math.trunc(total);
        total= total/100;
      }
      return total
    }));
  }

  private computeOrderTotalCostWithoutDiscount() {
    this.orderTotalCostWithoutDiscount = this.orderPaymentAmounts$.pipe(map(res => {
      let total: number = 0
      total = res.analysis.itemsTotal + (res.analysis.checkoutItemsTotal || 0) + (res.analysis.delivery || 0);
      if(total<=0)
        total=0;
      else{
        total=Math.round(total* 100);                // Round to 2 decimal places (since it's the order's total cost)
        total= Math.trunc(total);
        total= total/100;
      }
      return total
    }));
  }

  async updateActivePromoDetails(orderContent: Order, activePromo: ActivePromo) {
    let orderPaymentAmounts = this.orderPaymentAmounts$.getValue();
    if (orderContent && orderContent.shopId > 0) {
      if (activePromo && activePromo.shop_id === orderContent.shopId) {
        await this.updateActivePromoDetailsPromoApplied(orderContent, activePromo, orderPaymentAmounts);
      }
      else {
        await this.updateActivePromoDetailsNoPromoApplied(orderContent, orderPaymentAmounts);
      }
    }
    else {
      this.activePromoDetails$.next(null);
      if (orderPaymentAmounts.analysis?.discount) {
        orderPaymentAmounts.analysis.discount = 0;
        delete orderPaymentAmounts.extra;
        this.orderPaymentAmounts$.next(orderPaymentAmounts);
      }
    }
  }

  private async updateActivePromoDetailsPromoApplied(orderContent: Order, activePromo: ActivePromo, orderPaymentAmounts: OrderPaymentAmounts) {
    this.activePromoDetails$.next({isLoading: true});
    try {
      let order = null;
      const actPr: Promo = await this.ordersService.getOrderPromos({...orderContent, ...(activePromo.id.startsWith("c_") ? {couponId: +activePromo.id.substring(2)} : {offerId: +activePromo.id.substring(2)})}).pipe(
          map(promos => {
            let activePromoDetails: Promo = null;
            order = promos.order;

            if (promos.coupons.length > 0) {
              activePromoDetails = {
                id: `c_${promos.coupons[0].id}`,
                isCoupon: true,
                shop_id: activePromo.shop_id,
                title: promos.coupons[0].offer.title,
                description: promos.coupons[0].offer.description,
                couponName: promos.coupons[0].name,
                conditions: promos.coupons[0].offer.conditions,
                conditions_validity: promos.coupons[0].offer.conditions_validity,
                message: promos.coupons[0].message,
                discount: promos.coupons[0].discount,
                generalDiscount: promos.coupons[0].generalDiscount,
                offerDiscount: promos.coupons[0].offerDiscount,
                deliveryMethodDiscount: promos.coupons[0].deliveryMethodDiscount,
                gift: promos.coupons[0].gift
              };
            } else if (promos.offers.length > 0) {
              activePromoDetails = {
                id: `o_${promos.offers[0].id}`,
                isCoupon: false,
                shop_id: activePromo.shop_id,
                title: promos.offers[0].title,
                description: promos.offers[0].description,
                conditions: promos.offers[0].conditions,
                conditions_validity: promos.offers[0].conditions_validity,
                message: promos.offers[0].message,
                discount: promos.offers[0].discount,
                generalDiscount: promos.offers[0].generalDiscount,
                offerDiscount: promos.offers[0].offerDiscount,
                deliveryMethodDiscount: promos.offers[0].deliveryMethodDiscount,
                gift: promos.offers[0].gift
              };
            }
            if (activePromoDetails?.gift) {
              this.crosscheckFreeItems(activePromoDetails.gift);
            }

            return activePromoDetails;
          })
      ).toPromise()
      this.activePromoDetails$.next({isLoading: false, value: actPr});
      if (actPr.discount) {
        orderPaymentAmounts.analysis.discount = actPr.discount;
        orderPaymentAmounts.analysis.offerDiscount = actPr.offerDiscount;
        orderPaymentAmounts.analysis.globalDiscount = actPr.generalDiscount;
        orderPaymentAmounts.analysis.deliveryMethodDiscount = actPr.deliveryMethodDiscount;
        if(order && order.generalDiscount) {
          orderPaymentAmounts.analysis.globalDiscountPercentage = order.generalDiscount;
        }
        orderPaymentAmounts.extra = {discountName: actPr.title};
        this.orderPaymentAmounts$.next(orderPaymentAmounts);
      } else {
        this.cleanAnalysisDiscounts(orderPaymentAmounts);
        this.orderPaymentAmounts$.next(orderPaymentAmounts);
      }
    } catch (err) {
      this.activePromoDetails$.next({isLoading: false, error: err});
      this.setActivePromo(null, activePromo.shop_id);
      if (orderPaymentAmounts.analysis?.discount) {
        this.cleanAnalysisDiscounts(orderPaymentAmounts);
        this.orderPaymentAmounts$.next(orderPaymentAmounts);
      }
    }
  }

  private async updateActivePromoDetailsNoPromoApplied(orderContent: Order, orderPaymentAmounts: OrderPaymentAmounts) {
    try {
      const discounts = await this.getDiscounts(orderContent);
      if (discounts.discount) {
        orderPaymentAmounts.analysis.discount = discounts.discount;
        orderPaymentAmounts.analysis.globalDiscount = discounts.generalDiscount;
        orderPaymentAmounts.analysis.deliveryMethodDiscount = discounts.deliveryMethodDiscount;
        orderPaymentAmounts.analysis.categoryDiscount = discounts.categoryDiscount;
        delete orderPaymentAmounts.extra;
        this.orderPaymentAmounts$.next(orderPaymentAmounts);
      } else {
        this.cleanAnalysisDiscounts(orderPaymentAmounts);
        this.orderPaymentAmounts$.next(orderPaymentAmounts);
      }
    } catch (err) {
      if (orderPaymentAmounts.analysis?.discount) {
        this.cleanAnalysisDiscounts(orderPaymentAmounts);
        this.orderPaymentAmounts$.next(orderPaymentAmounts);
      }
    }
  }

  private cleanAnalysisDiscounts(orderPaymentAmounts: OrderPaymentAmounts) {
    orderPaymentAmounts.analysis.globalDiscount = 0;
    orderPaymentAmounts.analysis.deliveryMethodDiscount = 0;
    orderPaymentAmounts.analysis.categoryDiscount = 0;
    orderPaymentAmounts.analysis.discount = 0;
    delete orderPaymentAmounts.extra;
  }

  private async getDiscounts(orderContent: Order) {
    const order: any = await this.ordersService.getOrderPromos({...orderContent}).pipe(
        map((promos: any) => {
          return promos.order;
        })
    ).toPromise()
    return order;
  }

  crosscheckFreeItems(gifts: Array<ProductDetails>) {
    let freeItems: Array<BasketProduct> = this.freeItems$.getValue();
    let bValidate: boolean = false;

    // freeItems not included in gifts should be removed
    for (let idx = freeItems.length - 1; idx >= 0; idx-- ) {
      if (!gifts.map(gft => gft.id).includes(freeItems[idx].id)) {
        freeItems.splice(idx, 1);
        bValidate = true;
      }
    }
    if (bValidate) {
      this.freeItems$.next(freeItems);
    }

    // remove freeItems that are included in gifts but have 
    // different quantity (they will be added with the correct quantity later
    gifts.filter(gift => freeItems?.map(itm => itm.id).includes(gift.id)).forEach(gift => {
      let totalItemQuantity: number = 0;
      let matchedFreeItems: Array<BasketProduct> = freeItems.filter(itm => itm.id === gift.id);

      matchedFreeItems.forEach(frItm => {
        totalItemQuantity += frItm.quantity
      });
      
      if (gift.quantity !== totalItemQuantity) {
        freeItems = freeItems.filter(itm => itm.id !== gift.id)
        this.freeItems$.next(freeItems);
      }
    });

    //gifts not included in freeItems should be added
    gifts.filter(gift => !freeItems?.map(itm => itm.id).includes(gift.id)).forEach(gift => {
      
      if (gift.groups?.length > 0 && gift.quantity > 1) {
        for (let k = 0; k < gift.quantity; k++) {
          this.addToFreeItems(gift, 1);
        }
      }
      else {
        this.addToFreeItems(gift, gift.quantity);
      }

    });

  }

  addToFreeItems(itm: ProductDetails, qty: number) {
    let userSelections: Array<BasketGroup> = [];
      let bNeedsEdit: boolean = false;
        
      for (let group of itm.groups) {
        let currentSel: BasketGroup = {id: group.id, name: group.name, options: {opt_items: []}};
        const defaultValue = group.options.find(option => Boolean(option.default_item)) || group.options[0];

        if (group.type === 'select') {
          currentSel.options.opt_items.push({id: defaultValue.id, qty: 1});
          currentSel.selectionsDescription = defaultValue.name;
          bNeedsEdit = true;
        }
        else if (group.type === 'check') {
          currentSel.options.opt_items = [];
          currentSel.options.opt_sel = [];
          group.options.forEach((opt, idx) => {
            currentSel.options.opt_sel.push(false);
          });
          currentSel.selectionsDescription = '';
        }
        else if (group.type === 'enum') {
          currentSel.options.opt_items = [];
          currentSel.selectionsDescription = '';
        }

        userSelections.push(currentSel);
      }

      let prod: BasketProduct = {
        id: itm.id,
        category_id: itm.category_id,
        name: itm.name,
        quantity: qty,
        totalCost: 0,
        notes: itm.notes,
        groups: JSON.parse(JSON.stringify(userSelections)),
        outOfStock: itm.outOfStock,
        hidden: itm.hidden,
        needsEdit: bNeedsEdit,
        description:itm.description,
        images:itm.images
      };

      this.editFreeItem(-1, prod);
  }

  async populateBasket(): Promise<void> {
    this.basketLoading = true;
    this.basketErrorMessage = '';
    let bsk: BasketRest = this.guestBasket$.getValue();
    if (!bsk.items?.length) {
      if (this.userBasketRef) {
        let firebaseSnapshot: any = null;
        try {
          firebaseSnapshot = await this.userBasketRef.once('value');
          if (firebaseSnapshot?.val() && firebaseSnapshot.val().items) {
            bsk = firebaseSnapshot.val();
          }
          else {
            bsk = {shop_id: 0, items: []};
          }
        }
        catch(err) {
          this.basketErrorMessage = 'USER_SRV.ERR_NO_WEB_ACCESS';
          this.basketLoading = false;
          return;
        }
      }
      else {
        bsk = {shop_id: 0, items: []};
      }
    }

    try {
      let resBsk = await this.retrieveBasketContents(bsk);
      this._basketContents.next(resBsk);
      this.basketLoading = false;
    }
    catch(errBsk) {
      this._basketContents.next(errBsk);
      this.basketErrorMessage = 'ERR_SERVER_COMM';
      this.basketLoading = false;
    }
    return;
  }

  fetchCheckoutItems(shop_id): Promise<Array<BasketProduct>> {
    return new Promise((resolve, reject) => {

      let retVal: Array<BasketProduct> = [];
      this.productsService.getMenuCategories(shop_id, 1, true).toPromise().then(cats => {
        
        let requestsArray = [];
        let prodArray = [];
        for (let cat of cats) {
          for (let prod of cat.products) {
            prodArray.push(prod);
            requestsArray.push(this.productsService.getProductDetails(prod.id).toPromise());
          }
        }

        Promise.all(requestsArray).then(results => {
          for (let data of results) {
            if (data) {
              let idx = results.indexOf(data);
              let productGroups = data.groups;
              let basketGroups: Array<BasketGroup> = [];
              
              productGroups.forEach((group, index) => {
                let basketOptions: BasketOption;
                let selDescr: string = '';
                
                if (group.type === 'select') {
                  const defaultItem = group.options.find(option => Boolean(option.default_item)) ||  group.options[0];
                  
                  selDescr = defaultItem.name + (defaultItem.price > 0 ? ' (+' + defaultItem.price + ')' : '');
                  basketOptions = {opt_items: [{id: defaultItem.id, qty: 1}]};
                }
                else if (group.type === 'check') {
                  let tmp: BasketOption = {opt_items: [], opt_sel: []};
                  for (let opt of group.options) {
                    tmp.opt_sel.push(false);
                  }
                  basketOptions = tmp;
                }
                else if (group.type === 'enum') {
                  basketOptions = {opt_items: []};
                }

                let basketGroup: BasketGroup = {
                  id: group.id,
                  name: group.name,
                  selectionsDescription: selDescr,
                  options: basketOptions
                };

                basketGroups.push(basketGroup);
              });

              retVal.push({
                id: prodArray[idx].id,
                category_id: prodArray[idx].category_id,
                name: prodArray[idx].name, 
                hidden: prodArray[idx].hidden,
                quantity: 0, 
                totalCost: 0, 
                notes: '', 
                groups: basketGroups,
                description:prodArray[idx].description,
                images:prodArray[idx].images
              });
            }
          }
          resolve(retVal);
        }, err => {
          this.logger.warn(err);
          reject([]);
        });
      }, err => {
        this.logger.warn(err);
        reject([]);
      });
    });
  }

  addToBasket(shopId, productToAdd: BasketProduct): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let bskContents = this._basketContents.getValue();
      bskContents.shop_id = shopId;
        
      //Remove empty options
      productToAdd.groups = productToAdd.groups.filter(grp => grp.options?.opt_items?.length > 0);
      //////////////////////

      let idx = 0;
      let bSimilarFound = false;
      for (let itm of bskContents.items) {
        if (itm.id == productToAdd.id && ((productToAdd.groups || []).length ===  (itm.groups || []).length) && ((itm.notes || '') == (productToAdd.notes || ''))) {
          let optionsMismatch: boolean = false;
          for (let prodGrp of productToAdd.groups || []) {
            let bFound: boolean = false;
            for (let itmGrp of itm.groups || []) {
              if (prodGrp.id === itmGrp.id) {
                bFound = true;
                if (!prodGrp.selectionsDescription || !itmGrp.selectionsDescription || prodGrp.selectionsDescription !== itmGrp.selectionsDescription) {
                  optionsMismatch = true;
                }
                break;
              }
            }
            if (!bFound) optionsMismatch = true;
            if (optionsMismatch) break;
          }
          
          bSimilarFound = !optionsMismatch;
          break;
        }
        idx++;
      }

      if (bSimilarFound) {
        bskContents.items[idx].quantity += productToAdd.quantity;
        bskContents.items[idx].totalCost += productToAdd.totalCost;
      }
      else {
        bskContents.items.push(
          {
            id: productToAdd.id,
            category_id: productToAdd.category_id,
            quantity: productToAdd.quantity,
            groups: JSON.parse(JSON.stringify(productToAdd.groups)),
            notes: productToAdd.notes,
            name: productToAdd.name,
            totalCost: productToAdd.totalCost,
            outOfStock: productToAdd.outOfStock,
            hidden: productToAdd.hidden,
            needsEdit: productToAdd.needsEdit,
            description: productToAdd.description,
            images: productToAdd.images
          });
      }

      bskContents.totalCost += productToAdd.totalCost;

      this._basketContents.next(bskContents);
      
      resolve(true);
    });

  }

  editFreeItem(idx: number, product: BasketProduct): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let freeItems = this.freeItems$.getValue();
      
      //Remove empty options
      product.groups = product.groups.filter(grp => grp.options?.opt_items?.length > 0);
      //////////////////////

      if (idx === -1) {
        freeItems.push(
          {
            id: product.id,
            category_id: product.category_id,
            quantity: product.quantity,
            groups: JSON.parse(JSON.stringify(product.groups)),
            notes: product.notes,
            name: product.name,
            totalCost: product.totalCost,
            outOfStock: product.outOfStock,
            hidden: product.hidden,
            needsEdit: product.needsEdit,
            description: product.description,
            images: product.images
          }
        );
      }
      else {
        freeItems[idx] = product;
      }

      this.freeItems$.next(freeItems);
      resolve(true);
    });
  }

  storeBasket(bskContents: BasketContents) {
    let bsk: BasketRest = {shop_id: 0, items: []};
    bsk.shop_id = bskContents.shop_id;

    for (let itm of bskContents.items) {
      let basketProduct = {
        id: itm.id,
        quantity: itm.quantity,
        notes: itm.notes,
        groups: []
      };
      if (itm.groups) {
        for (let grp of itm.groups) {
          if (grp.selectionsDescription) {
            let basketGroup: BasketGroup = {
              id: grp.id,
              options: {
                opt_items: JSON.parse(JSON.stringify(grp.options.opt_items))
              }
            }
            basketProduct.groups.push(basketGroup);
          }
        }
      }
      bsk.items.push(basketProduct);
    }

    if ('checkout' in bskContents) {
      bsk.checkout = [];
      for (let itm of bskContents.checkout) {
        let basketProduct = {
          id: itm.id,
          quantity: itm.quantity,
          notes: itm.notes,
          groups: []
        };
        if(itm.groups)
        {
          for (let grp of itm.groups) {
            if (grp.selectionsDescription) {
              let basketGroup: BasketGroup = {
                id: grp.id,
                options: {
                  opt_items: JSON.parse(JSON.stringify(grp.options.opt_items))
                }
              }
              basketProduct.groups.push(basketGroup);
            }
          }
        }
        
        if (itm.quantity > 0) {
          bsk.checkout.push(basketProduct);
        }
      }
    }

    if (this.userBasketRef) {
      this.userBasketRef.update(bsk);
    }
    else {
      this.guestBasket$.next(bsk);
    }

  }

  modifyBasket(idx: number, isCheckout: boolean, productToEdit: BasketProduct): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let bsk = this._basketContents.getValue();
      let collection = (!isCheckout ? bsk.items : bsk.checkout);
      
      //Remove empty options
      productToEdit.groups = productToEdit.groups.filter(grp => grp.options?.opt_items?.length > 0);
      //////////////////////

      if (!isCheckout) {
        let tmpIdx = 0;
        let bSimilarFound = false;
        for (let itm of collection) {
          if (tmpIdx !== idx && itm.id == productToEdit.id && ((productToEdit.groups || []).length ===  (itm.groups || []).length) && ((itm.notes || '') == (productToEdit.notes || ''))) {
            let optionsMismatch: boolean = false;
            for (let prodGrp of productToEdit.groups || []) {
              let bFound: boolean = false;
              for (let itmGrp of itm.groups || []) {
                if (prodGrp.id === itmGrp.id) {
                  bFound = true;
                  if (!prodGrp.selectionsDescription || !itmGrp.selectionsDescription || prodGrp.selectionsDescription !== itmGrp.selectionsDescription) {
                    optionsMismatch = true;
                  }
                  break;
                }
              }
              if (!bFound) optionsMismatch = true;
              if (optionsMismatch) break;
            }
            
            bSimilarFound = !optionsMismatch;
            break;
          }
          tmpIdx++;
        }

        if (bSimilarFound) {
          collection[tmpIdx].quantity += productToEdit.quantity;
          collection[tmpIdx].totalCost += productToEdit.totalCost;
          collection.splice(idx, 1);
        }
        else {
          collection[idx] = productToEdit;
        }
      }
      else {
        collection[idx] = productToEdit;
      }
      
      
      this.updateTotal();
      resolve(true);
    });
  }

  sendSpeedOrderToBasket(speedOrder: OrderSpeedRest): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.speedOrderToBasketContents(speedOrder).then(resBsk => {
        this._basketContents.next({...resBsk});
        resolve(true);
      }, errBsk => {
        resolve(false);
      });
    });
  }

  private speedOrderToBasketContents(speedOrder: OrderSpeedRest): Promise<BasketContents> {
    return new Promise((resolve, reject) => {
      let bskContents: BasketContents = {
        shop_id: 0,
        items: [],
        checkout: [],
        note: '',
        totalCost: 0
      };
      
      let requestProductsArray = [];
      
      if (speedOrder.products.length == 0) {
        resolve(bskContents);
      }
      else {

        bskContents.shop_id = speedOrder.shopId;
        bskContents.note = speedOrder.note;
        this.basketLoading = true;
        this.fetchCheckoutItems(bskContents.shop_id).then(rtn => {
          bskContents.checkout = rtn;

          for (let prod of speedOrder.products) {
            requestProductsArray.push(this.productsService.getProductDetails(prod.id));
          }

          let obs: Observable<Array<ProductDetails>> = forkJoin(requestProductsArray);
          obs.pipe().subscribe(results => {
            let result: ProductDetails;
            
            for (result of results) {
              if (result) {
                let idx = results.indexOf(result);
                let itm: ProductRest = speedOrder.products[idx];

                let itm_options = itm.options || [];
                let unitCost: number = result.price;
                let basketProduct: BasketProduct = {
                  id: itm.id,
                  category_id: result.category_id,
                  name: result.name,
                  quantity: (itm.quantity || 0),
                  notes: (itm.note || ''),
                  totalCost: 0,
                  groups: [],
                  outOfStock: result.outOfStock,
                  hidden: result.hidden,
                  description: result.description,
                  images: result.images
                };

                for (let itmOpt of itm_options) {
                  let basketGroup: BasketGroup = {
                    id: itmOpt.groupId,
                    name: '',
                    selectionsDescription: '',
                    options: {
                      opt_items: []
                    }
                  };
                  
                  if ('groups' in result) {
                    for (let group of (result.groups || [])) {
                      if (itmOpt.groupId == group.id) {
                        basketGroup.name = group.name;
                        for (let optItm of itmOpt.items) {
                          for (let opt of group.options) {
                            if (optItm.id == opt.id) {

                              if (group.type === 'enum' && (optItm.qty || 0) > 0) {
                                basketGroup.selectionsDescription += (basketGroup.selectionsDescription.length > 0 ? ', ' : '') + basketGroup.name + ' x' + optItm.qty + (opt.price > 0 ? ' (+' + (opt.price * optItm.qty).toFixed(2) + ')' : '');
                              }
                              else {
                                basketGroup.selectionsDescription += (basketGroup.selectionsDescription.length > 0 ? ', ' : '') + opt.name + (opt.price > 0 ? ' (+' + (opt.price * 1).toFixed(2) + ')' : '')
                              }

                              basketGroup.options.opt_items.push({id: optItm.id, qty: optItm.qty || 1});
                              unitCost += (opt.price * (optItm.qty || 1));
                              break;
                            }
                          }
                        }
                      }
                    }
                  }
                  basketProduct.groups.push(basketGroup);
                }
                
                basketProduct.totalCost = unitCost * itm.quantity;
                              
                let bIsCheckout: boolean = false;
                for (let rtnItem of bskContents.checkout) {
                  if (rtnItem.id == itm.id) {
                    rtnItem.category_id = basketProduct.category_id,
                    rtnItem.quantity = basketProduct.quantity;
                    rtnItem.notes = basketProduct.notes;
                    rtnItem.outOfStock = basketProduct.outOfStock;
                    rtnItem.hidden = basketProduct.hidden;
                    rtnItem.groups = basketProduct.groups;
                    rtnItem.totalCost = unitCost;
                    rtnItem.name = basketProduct.name;
                    rtnItem.description= basketProduct.description;
                    rtnItem.images= basketProduct.images;
                    bskContents.totalCost += unitCost * itm.quantity;
                    bIsCheckout = true;
                    break;
                  }
                }

                if (!bIsCheckout) {
                  bskContents.items.push(basketProduct);
                  bskContents.totalCost += basketProduct.totalCost;
                }
              }
            }
            
            this.basketLoading = false;
            resolve(bskContents);
              
          }, error => {
            this.basketLoading = false;
            reject({ shop_id: 0, items: [], totalCost: 0 });
          });
          

        });
      }

    });
    
  }

  isBasketEmpty(): boolean {
    let bsk = this._basketContents.getValue();
    return ((typeof bsk.items === "undefined") || (bsk.items.length < 1));
  }

  // changeQuantity(itemIdx:number,quantity:number){
    
  //   let bskContents = this._basketContents.getValue();
  //   if (bskContents.items && bskContents.items.length > itemIdx) {
  //     bskContents.items[itemIdx].quantity=quantity;
  //       this.updateTotal();
  //   }
  //   if (bskContents.checkout && bskContents.checkout.length > itemIdx) {
  //     bskContents.checkout[itemIdx].quantity = quantity; 
      
  //     this.updateTotal();
  //   }
  // }

  removeItem(idx) {

    let bskContents = this._basketContents.getValue();
    if (bskContents.items) {
      bskContents.items.splice(idx, 1);
      
      // if ((typeof bskContents.items === "undefined") || (bskContents.items.length === 0)) {                // Reason: QDS-1856 > Do not ask for delivery type WHEN removing items from the basket!
      //   this.removeAllBasketItems();
      // }
      // else {
        this.updateTotal();
      // }
    }
  }

  removeCheckoutItem(idx) {

    let bskContents = this._basketContents.getValue();
    if (bskContents.checkout) {
      bskContents.checkout[idx].quantity = 0;
      bskContents.checkout[idx].totalCost = 0;
      
      this.updateTotal();
    }
  }

  removeAllBasketItems() {
    let bsk:BasketContents = {shop_id: 0, items: [], checkout: [], totalCost: 0};
    this._basketContents.next(bsk);
  }

  clearBasketAndKeepOrderType(shop_id: number) {
    let orderContent: Order = this.orderContent$.getValue();
    if (orderContent) {
      orderContent = {
        shopId: shop_id,
        dat: 0,
        type: orderContent.type,
        payment: {
          amount: 0,
          method: ""
        },
        products: [],
      }
      this.orderContent$.next(orderContent);
    }
    let bsk:BasketContents = {shop_id: shop_id, items: [], checkout: [], totalCost: 0};
    this._basketContents.next(bsk);
  }

  clearBasketForCurrentShop(shop_id: number) {
    this.orderContent$.next(null);
    let bsk:BasketContents = {shop_id: shop_id, items: [], checkout: [], totalCost: 0};
    this._basketContents.next(bsk);
  }


  private retrieveBasketContents(basket: BasketRest): Promise<BasketContents> {
    return new Promise((resolve, reject) => {
      let bskContents: BasketContents = {
        shop_id: 0,
        items: [],
        checkout: [],
        totalCost: 0
      };
      
      let requestItemsArray = [];
      let requestCheckoutArray = [];

      if (basket.items.length == 0) {
        resolve(bskContents);
      }
      else {

        bskContents.shop_id = basket.shop_id;
        this.basketLoading = true;
        this.fetchCheckoutItems(bskContents.shop_id).then(rtn => {
          bskContents.checkout = rtn;

          let cnt = basket.items.length;

          while (cnt--) {
            if ('id' in basket.items[cnt]) {

              let checkOutItemsIds: number[] = rtn.map(checkOutItem => checkOutItem.id);
              if (checkOutItemsIds.includes(basket.items[cnt].id)) {
                //this item has been moved to checkout --- act appropriately
                if (!('checkout' in basket)) {
                  basket['checkout'] = [];
                }
                basket.checkout.push(basket.items[cnt]);
                basket.items.splice(cnt, 1);
              }
              else {
                requestItemsArray.unshift(this.productsService.getProductDetails(basket.items[cnt].id));
              }
            }
            else {
              basket.items.splice(cnt, 1);
            }
          }

          if ('checkout' in basket) {
            cnt = basket.checkout.length;
            while (cnt--) {
              if ('id' in basket.checkout[cnt]) {
                requestCheckoutArray.unshift(this.productsService.getProductDetails(basket.checkout[cnt].id));
              }
              else {
                basket.checkout.splice(cnt, 1);
              }
            }
          }
          this.productsService.getShopPromos(basket.shop_id).subscribe(shopPromo => {
            this.cafesService.getCafeDetails(basket.shop_id).subscribe(shopDetails => {
              let generalDiscount = shopPromo.generalDiscount;
              let requestsArray = requestItemsArray.concat(requestCheckoutArray);

              let obs: Observable<any> = forkJoin(requestsArray);
              obs.pipe().subscribe(results => {
                let result: any;

                for (result of results) {
                  if (result) {
                    let idx = results.indexOf(result);
                    let itm = ((idx < basket.items.length) ? basket.items[idx] : basket.checkout[idx - basket.items.length]);
                    // let itm = basket.items[idx];
                    let itm_groups = itm.groups || [];
                    let unitCost: number = parseFloat(result.price);

                    for (let grp of itm_groups) {
                      grp.selectionsDescription = '';

                      if ('groups' in result) {
                        for (let group of (result.groups || [])) {
                          if (grp.id == group.id) {
                            grp.name = group.name;
                            for (let optItm of grp.options?.opt_items || []) {
                              for (let opt of group.options) {
                                if (optItm.id == opt.id) {
                                  if (group.type === 'enum' && optItm.qty > 0) {
                                    grp.selectionsDescription += (grp.selectionsDescription.length > 0 ? ', ' : '') + opt.name + ' x' + optItm.qty + (opt.price > 0 ? ' (+' + this.getOptionPriceForDescription(opt, optItm, generalDiscount, shopDetails).toFixed(2) + ')' : '');

                                  }
                                  else {
                                    grp.selectionsDescription += (grp.selectionsDescription.length > 0 ? ', ' : '') + opt.name + (opt.price > 0 ? ' (+' + this.getOptionPriceForDescription(opt, optItm, generalDiscount, shopDetails).toFixed(2) + ')' : '')
                                  }
                                  unitCost += (opt.price * optItm.qty);
                                  break;
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    itm.totalCost = unitCost * itm.quantity;
                    itm.name = result.name;
                    itm.category_id = result.category_id;
                    itm.outOfStock = result.outOfStock;
                    itm.hidden = result.hidden;
                    itm.description= result.description;
                    itm.images= result.images;

                    if (idx < basket.items.length) {
                      bskContents.items.push(itm);
                      bskContents.totalCost += itm.totalCost;
                    }
                    else {
                      for (let rtnItem of bskContents.checkout) {
                        if (rtnItem.id == itm.id) {
                          rtnItem.category_id = result.category_id;
                          rtnItem.name = result.name;
                          rtnItem.outOfStock = result.outOfStock;
                          rtnItem.hidden = result.hidden;
                          rtnItem.quantity = itm.quantity;
                          rtnItem.notes = itm.notes;
                          rtnItem.groups = itm.groups;
                          rtnItem.totalCost = unitCost * itm.quantity;
                          rtnItem.description= result.description;
                          rtnItem.images= result.images;
                          bskContents.totalCost += rtnItem.totalCost;
                          break;
                        }
                      }
                    }
                  }
                }

                this.basketLoading = false;
                resolve(bskContents);

              }, error => {
                this.basketLoading = false;
                reject({ shop_id: 0, items: [], totalCost: 0 });
              });
            })
          });
        }, error => {
          this.basketLoading = false;
          reject({ shop_id: 0, items: [], totalCost: 0 });
        });
      }

    });

  }

  private getOptionPriceForDescription(opt, optItm: any, generalDiscount: number, shopDetails: any) {
    let orderContent: Order = this.orderContent$.getValue();
    return computeDiscountedPrice(opt.price * optItm.qty, generalDiscount, null, orderContent?.type);
  }

  updateTotal() {
    let tl = 0;
    let bskContents = this._basketContents.getValue();
    for (let itm of bskContents.items) {
      tl += itm.totalCost;
    }

    bskContents.totalCost = tl;
    
    if ('checkout' in bskContents) {
      tl = 0;
      for (let itm of bskContents.checkout) {
        tl += itm.totalCost;
      }
      bskContents.totalCost += tl;
    }

    this._basketContents.next(bskContents);
  }

  convertBasketContentToOrderProducts(bsk: BasketContents): Array<Product> {
    let orderProducts: Array<Product> = [];
    const allItems = bsk.items.concat(bsk.checkout?.filter(prd => prd.quantity > 0) || []);
    for (let prod of allItems) {
      let tmpProd: Product = {
        id: prod.id,
        quantity: prod.quantity,
        note: prod.notes,
        options: [],
        gift: false
      };

      for (let grp of prod.groups || []) {
        let tmpOpt: ProductOptions = {
          groupId: grp.id,
          items: JSON.parse(JSON.stringify(grp.options?.opt_items || []))
        };
        tmpProd.options.push(tmpOpt);
      }
      orderProducts.push(tmpProd);
    }
    return orderProducts;
  }

  getBasket(): BasketContents {
    return this._basketContents.getValue();
  }

  setActivePromo(val: ActivePromo | null, shopId: number) {
    this.activePromo$.next(val);

    if (val) {
      const promoIndex = this.activePromoAllShops$.value.findIndex(p => p.shop_id === val.shop_id);
      if (promoIndex !== -1) {
        this.activePromoAllShops$.value.splice(promoIndex, 1);
      }
      this.activePromoAllShops$.value.push(val);
      this.activePromoAllShops$.next(this.activePromoAllShops$.value);
    } else {
      if (shopId === 0) {
        this.activePromoAllShops$.next([null]);
      } else {
        const promoIndex = this.activePromoAllShops$.value.findIndex(p => p.shop_id === shopId);
        if (promoIndex !== -1) {
          this.activePromoAllShops$.value.splice(promoIndex, 1)
          this.activePromoAllShops$.next(this.activePromoAllShops$.value);
        }
      }
    }

    if (!val) {
      this.activePromoDetails$.next(null);
      //remove free items if any
      let freeItems = this.freeItems$.getValue();
      if (freeItems.length > 0) {
        this.freeItems$.next([]);
      }
    }
  }

}
