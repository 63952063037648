import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject, Observable } from 'rxjs';

const THEME_KEY: string = "selected-app-theme";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  
  private _darkMode: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly darkMode: Observable<boolean> = this._darkMode.asObservable();

  constructor(private platform: Platform) { 
    this.platform.ready().then(() => {
      Preferences.get({key: THEME_KEY}).then((data) => {
        this.setAppTheme(data.value == "true");
      });

      // const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
      // prefersDark.addListener(e => {
      //   this.setAppTheme(e.matches);
      // });
    });

  }

  setAppTheme(dark: boolean) {
    this._darkMode.next(dark);

    if (dark) {
      document.body.classList.add("dark");
      // this.statusBar.styleBlackOpaque();
      // this.statusBar.backgroundColorByHextring("#000000");
    }
    else {
      document.body.classList.remove("dark");
      // this.statusBar.styleDefault();
      // this.statusBar.backgroundColorByHextring("#ffffff");
    }

    Preferences.set({ key: THEME_KEY, value: String(this._darkMode.getValue()) });


  }

  getDarkMode() {
    return this._darkMode.getValue();
  }


}
