import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UpdateVersionModalComponent } from './update-version-modal.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
  ],
  declarations: [UpdateVersionModalComponent],
  exports: [UpdateVersionModalComponent]
})
export class UpdateVersionModalPageModule {}
