import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceInfo } from '../../models/device-info.interface';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';
import { Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { PluginListenerHandle, Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { Network } from '@capacitor/network';
import { datadogLogs } from '@datadog/browser-logs';
import { UserDataService } from '../user-data/user-data.service';

interface UpdateDeviceResponse {
  device: any;
}

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private _SETTINGS_LANG = 'lang';
  supportedLanguages: Array<{code: string, name: string; country: string}> = [
    {code:'en', name:'English', country: 'en-US'},
    {code:'el', name:'Ελληνικά', country: 'el-GR'}
  ];

  selectedLanguage: BehaviorSubject<string> = new BehaviorSubject("en");
  private _userToken: string | null = null;

  private networkHandler:PluginListenerHandle = null;
  private _deviceInfo: DeviceInfo = {uuid: '', os: '', osVersion: '', locale: '', timezone: '', pushToken: ''};
  private _networkConnection: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _pushEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly networkConnection: Observable<boolean> = this._networkConnection.asObservable();
  public readonly pushEnabled: Observable<boolean> = this._pushEnabled.asObservable();
  // private _deviceInfo: BehaviorSubject<DeviceInfo> = new BehaviorSubject({uuid: '', os: '', osVersion: '', locale: '', timezone: '', pushToken: ''});
  // public readonly deviceInfo: Observable<DeviceInfo> = this._deviceInfo.asObservable();

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private platform: Platform,
    private logger: NGXLogger,
    private userDataService: UserDataService,
    private globalization: Globalization
  ) {
    
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    this.userDataService.userToken$.subscribe(token => {
      if (token) {
        this._userToken = token;

        if (Capacitor.isNativePlatform()) { //add native calls
          this.addDevice().then(params => {
            this.logger.log("Device added successfully");
          }, err => {
            this.logger.error("Error adding device.");
            this.logger.info(err);
          });
        }
      }
      else {
        if (this._userToken) {

          if (Capacitor.isNativePlatform()) { //add native calls
            this.removeDevice().then(() => {
              this.logger.log("Device removed successfully");
            }, err => {
              this.logger.error("Error removing device.");
              this.logger.info(err);
              datadogLogs.logger.error('Error removing device.', {error: err, userToken: this._userToken});
            });
          }
        }
        this._userToken = null;
      }

    });

    Network.getStatus().then(status => {
      this._networkConnection.next(status.connected);
    });

    Network.addListener('networkStatusChange', (status) => {
      this._networkConnection.next(status.connected);
    }).then(val => this.networkHandler = val);

    this.platform.ready().then(() => {

      if (Capacitor.isNativePlatform()) {
        Device.getId().then(val => {
          this._deviceInfo.uuid = val.identifier;
        });

        Device.getInfo().then((devInfo) => {
          this._deviceInfo.os = devInfo.platform;
          this._deviceInfo.osVersion = devInfo.osVersion;
        });
        
        this.globalization.getLocaleName().then(loc => {
          this._deviceInfo.locale = loc.value;
        });
        
        this.globalization.getDatePattern({formatLength:'short', selector:'date and time'}).then(res => {
          this._deviceInfo.timezone = res.timezone;
        });
      }
    });

  }

  initLocale() {

    Preferences.get({ key: this._SETTINGS_LANG}).then((res) => {
      let userLang = 'en';
      let navLang = 'en';

      if (res && ('value' in res) && res.value) {
        navLang = res.value;
      }
      else {
        navLang = navigator.language.split('-')[0];
      }

      // Verify language code
      for (let lang of this.supportedLanguages) {
        if (lang.code === navLang) { userLang = navLang; break; }
      }
      this.setLanguage(userLang);
    }); 

  }

  setLanguage(langCode) {
    this.selectedLanguage.next(langCode);
    this.translate.use(langCode);
    Preferences.set({key: this._SETTINGS_LANG, value: langCode});
  }

  getLanguageName(langCode): string {
    let str = '';
    for (let lang of this.supportedLanguages) {
      if (lang.code === langCode) return lang.name;
    }

    return str;
  }

  getLanguageCountry(langCode): string {
    let str = '';
    for (let lang of this.supportedLanguages) {
      if (lang.code === langCode) return lang.country;
    }

    return str;
  }

  getSelectedLanguage(): string {
    return this.selectedLanguage.getValue();
  }

  updatePushToken(token) {
    this._deviceInfo.pushToken = token;
  }

  pushNotificationsPermissionGranted(bVal: boolean) {
    this._pushEnabled.next(bVal);
  }

  addDevice(): Promise<any> {

    if (this._userToken && this._deviceInfo.pushToken) {

      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + this._userToken);
      
      let url_params: HttpParams = new HttpParams();
      url_params = url_params.append('token', this._deviceInfo.pushToken);
      url_params = url_params.append('type', 'user');
      url_params = url_params.append('os', this._deviceInfo.os);
      url_params = url_params.append('osVersion', this._deviceInfo.osVersion);
      // url_params = url_params.append('locale', this._deviceInfo.locale);
      url_params = url_params.append('locale', this.getLanguageCountry(this.getSelectedLanguage()));
      url_params = url_params.append('timezone', this._deviceInfo.timezone);
      // url_params.set('uuid', this._deviceInfo.uuid);

      return this.http.get<UpdateDeviceResponse>(environment.QpassConfiguration.apiUrl + '/user/addDevice', {headers: headers, params: url_params}).pipe(
        tap(res => datadogLogs.logger.info('Device added successfully', {userToken: this._userToken, pushToken: this._deviceInfo.pushToken, result: res})),
        map(res => res.device)
      ).toPromise();
    }
    else {
      return Promise.reject('Empty token. App: ' + this._userToken + ' Push: ' + this._deviceInfo.pushToken);
    }
  }

  removeDevice(): Promise<any> {
    
    if (this._userToken && this._deviceInfo.pushToken) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + this._userToken);
      
      let url_params: HttpParams = new HttpParams();
      url_params = url_params.append('token', this._deviceInfo.pushToken);
      return this.http.get<UpdateDeviceResponse>(environment.QpassConfiguration.apiUrl + '/user/removeDevice', {headers: headers, params: url_params}).pipe(
        tap(res => datadogLogs.logger.info('Device removed successfully', {userToken: this._userToken, pushToken: this._deviceInfo.pushToken, result: res})),
        map(res => res.device)
      ).toPromise();
    }
    else {
      return Promise.reject('Empty token. App: ' + this._userToken + ' Push: ' + this._deviceInfo.pushToken);
    }

  }



}
