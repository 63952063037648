import { DEFAULT_CURRENCY_CODE, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
// import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
// import { getAuth, provideAuth } from '@angular/fire/auth';
// import { getDatabase, provideDatabase } from '@angular/fire/database';
// import { getStorage, provideStorage } from '@angular/fire/storage';
import { IonicModule, IonicRouteStrategy, iosTransitionAnimation } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { LoggerModule } from 'ngx-logger';
import { SplashPageModule } from './pages/splash/splash.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { NgHttpCachingModule, NgHttpCachingStrategy } from 'ng-http-caching';
import { NgxStripeModule } from 'ngx-stripe';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { RequestIdInterceptor } from './interceptors/request-id.interceptor';
import { QpassCoreModule } from '@q-pass/core';
import { QpassUserModule } from '@q-pass/user';
import { ServiceWorkerModule } from '@angular/service-worker';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            navAnimation: iosTransitionAnimation,
            mode: 'ios'
        }),
        AppRoutingModule,
        NgHttpCachingModule.forRoot({
            lifetime: 60 * 1000, // cache expire after 60 seconds
            allowedMethod: ['GET'], // alllow only GET requests to be cached
            cacheStrategy: NgHttpCachingStrategy.DISALLOW_ALL // allow only requests explicitly specified to be cached
        }),
        QpassCoreModule.forRoot(
            environment.firebaseConfiguration,
            environment.QpassConfiguration,
            environment.datadogConfiguration
        ),
        QpassUserModule.forRoot(
            environment.QpassConfiguration
        ),
        NgxStripeModule.forRoot(environment.stripeConfiguration.pub_key),
        // provideFirebaseApp(() => initializeApp(environment.firebaseConfiguration)),
        // provideAuth(() => getAuth()),
        // provideDatabase(() => getDatabase()),
        // provideStorage(() => getStorage()),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        LoggerModule.forRoot(environment.logging),
        SplashPageModule,
        ReactiveFormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })], providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        Globalization,
        Badge,
        CallNumber,
        LaunchNavigator,
        NativeGeocoder,
        { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfiguration },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, },
        { provide: HTTP_INTERCEPTORS, useClass: RequestIdInterceptor, multi: true, },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
