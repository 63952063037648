import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UserDataService } from "../providers/user-data/user-data.service";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private userDataService: UserDataService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let userToken = this.userDataService.getUserToken();
    if(userToken){
      req = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${userToken}`,
        },
      });
    }
    return next.handle(req);
  }
}