import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { CallbackID, Geolocation } from '@capacitor/geolocation';
import BackgroundGeolocation, {
  Location,
  MotionChangeEvent,
} from "cordova-background-geolocation-lt";
import { datadogLogs } from '@datadog/browser-logs';
import { NGXLogger } from 'ngx-logger';
import { MapLocation } from 'src/app/models/map-point.interface';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { MyPlacesService } from '../my-places/my-places.service';
import { OrdersService } from '../orders/orders.service';
import { CafesService } from '../cafes/cafes.service';

@Injectable({
  providedIn: 'root'
})
export class BackgroundGeolocationService {

  public readonly ATHENS_COORDS = {lat: 37.983810, lng: 23.727539};

  private isWatchingPosition: boolean = false;
  private _watchId: CallbackID = null;
  private _shouldStart: boolean = false;
  
  public backgroundUserLocation$: Subject<MapLocation> = new Subject();
  public watchUserLocation$: Subject<MapLocation> = new Subject();
  private _bgState: BehaviorSubject<any> = new BehaviorSubject({});
  public readonly bgState: Observable<any> = this._bgState.asObservable();
  private _bgConfigured: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly bgConfigured: Observable<boolean> = this._bgConfigured.asObservable();

  constructor(
    private translateService: TranslateService,
    private ngZone: NgZone,
    private platform: Platform,
    private logger: NGXLogger
  ) {

    this.platform.pause.subscribe(async () => {
      if (this.isWatchingPosition) {
        this.stopWatchPosition();
      }
    });

    this.platform.resume.subscribe(async () => {
      if (this.isWatchingPosition) {
        this.startWatchPosition();
      }
    });
    
  }

  async configureBackgroundGeolocation() {
    this._bgConfigured.next(true);
    let bgPopupTitle = await this.translateService.get('APP.BACKGROUND_GEO_PLUGIN.PER_REQ.TITLE').toPromise();
    let bgPopupMessage = await this.translateService.get('APP.BACKGROUND_GEO_PLUGIN.PER_REQ.MESSAGE').toPromise();
    let bgPopupPositiveAction = await this.translateService.get('APP.BACKGROUND_GEO_PLUGIN.PER_REQ.POS_ACTION').toPromise();
    let bgPopupNegativeAction = await this.translateService.get('APP.BACKGROUND_GEO_PLUGIN.PER_REQ.NEG_ACTION').toPromise();

    // 1.  Listen to events.
    BackgroundGeolocation.onLocation(location => this.onLocation(location), err => console.error(err));
    BackgroundGeolocation.onMotionChange(event => this.onMotionChange(event));

    // 2.  Configure the plugin with #ready
    BackgroundGeolocation.ready({
      reset: true,
      debug: false,
      logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
      desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
      distanceFilter: 200,
      url: null,
      autoSync: true,
      stopOnTerminate: true,
      startOnBoot: false,
      backgroundPermissionRationale: {
        title: bgPopupTitle,
        message: bgPopupMessage,
        positiveAction: bgPopupPositiveAction,
        negativeAction: bgPopupNegativeAction
      }
    }, (state) => {
      if (state.enabled && !this._shouldStart) {
        BackgroundGeolocation.stop((st) => {
          this._bgState.next(st);
          datadogLogs.logger.info('Background geolocation stopped (configureBackgroundGeolocation).');
        });
      }
      else if (!state.enabled && this._shouldStart) {
        BackgroundGeolocation.start((st) => {
          BackgroundGeolocation.changePace(true);
          this._bgState.next(st);
          datadogLogs.logger.info('Background geolocation started (configureBackgroundGeolocation).');
        });
      }
      else {
        this._bgState.next(state);
      }

    });

  }

  onMotionChange(motEvent: MotionChangeEvent) {
    BackgroundGeolocation.getState((state) => {
      this._bgState.next(state);
    });  
  };

  onLocation(location: Location) {
    this.backgroundUserLocation$.next({
      position: { lat: location.coords.latitude, lng: location.coords.longitude },
      accuracy: location.coords.accuracy
    });
  }

  startTracking() {
    let st = this._bgState.getValue();
    if ('enabled' in st) {
      if (!st.enabled) {
        BackgroundGeolocation.start((state) => {
          this._bgState.next(state);
          datadogLogs.logger.info('Background geolocation started (startTracking).');
        });
      }
    }
    else {
      this._shouldStart = true;
    }
  }

  stopTracking() {
    let st = this._bgState.getValue();
    if ('enabled' in st) {
      if (st.enabled) {
        BackgroundGeolocation.stop((state) => {
          this._bgState.next(state);
          datadogLogs.logger.info('Background geolocation stopped (stopTracking).');
        });
      }
    }
    else {
      this._shouldStart = false;
    }
  }

  async requestCurrentPosition(): Promise<MapLocation> {
    let userLoc: MapLocation = null;
    try {
      userLoc = await this.getCurrentPosition(false);
      if (userLoc.accuracy > 100) {
        userLoc = null;
        throw("Location not accurate enough");
      }
    }
    catch(errUser) {
      try {
        userLoc = await this.getCurrentPosition(true);
      }
      catch(err) {
        throw('USER_SRV.ERR_NO_LOC');
      }
    }
    return userLoc;
  }

  async getCurrentPosition(enableHighAccuracy: boolean = true): Promise<MapLocation> {
    
    try {
      let location = await Geolocation.getCurrentPosition({maximumAge: 0, timeout: 3000, enableHighAccuracy: enableHighAccuracy});
      return({position: {lat: location.coords.latitude, lng: location.coords.longitude}, accuracy: location.coords.accuracy});
    }
    catch(err) {
      throw('USER_SRV.ERR_NO_LOC');
    }

  }

  watchPosition(enable: boolean) {
    this.isWatchingPosition = enable;
    if (enable) {
      this.startWatchPosition();
    }
    else {
      this.stopWatchPosition();
    }
  }

  private async startWatchPosition() {
    if (this._watchId === null) {
      try {
        this._watchId = await Geolocation.watchPosition({enableHighAccuracy: true, maximumAge: 0, timeout: 3000}, (position, err) => {
          this.ngZone.run(() => {
            if (position) {
              this.watchUserLocation$.next({
                position: { lat: position.coords.latitude, lng: position.coords.longitude },
                accuracy: position.coords.accuracy
              });
            }
          });
        });
      }
      catch(err) {
        this.logger.error("watchPosition error happened.");
        this.logger.error(err);
      }
    }
  }

  private async stopWatchPosition() {
    if (this._watchId != null) {
      try {
        await Geolocation.clearWatch({ id: this._watchId });
      }
      catch(err) {}
      this._watchId = null;
    }
  }

  isConfigured(): boolean {
    return this._bgConfigured.getValue();
  }

  isWatchingPositionEnabled() {
    return this.isWatchingPosition;
  }

}
